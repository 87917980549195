import { useCallback, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-solid-svg-icons';
import { AssetType } from '@remento/types/asset';
import { EntityType } from '@remento/types/entity';
import { PromptStatus, PromptType } from '@remento/types/project';

import askQuestionSrc from '@/assets/ask-question.svg';
import learnPhotoSrc from '@/assets/learn-photo.svg';
import { RMButtonList } from '@/components/RMButtonList';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { PromptQuestionDialog } from '@/modules/project-record/components/PromptQuestionDialog';
import { RecordingModeDialog } from '@/modules/project-record/components/RecordingModeDialog';
import { PromptPhotoDialogContainer } from '@/modules/project-record/containers/PromptPhotoDialog.container';
import { PromptTemplatePickerDialogContainer } from '@/modules/project-record/containers/PromptTemplatePickerDialog.container';
import { getProjectRecordPath, getRecordingPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { CreatePromptPayload, useProjectQuery } from '@/services/api/project';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';
import { captureException } from '@/utils/captureException';
import { FilestackHandle } from '@/utils/filestack';
import { secureUuid } from '@/utils/uuid';

import { Content, Header, Hidden, StyledProjectRecordCustomizePage } from './ProjectRecordCustomizePage.styles';

interface NewCustomPrompt {
  type: 'custom';
  question: string;
}

interface NewTemplatePrompt {
  type: 'template';
  template: PromptTemplate;
  tag: PromptTemplateTag;
}

interface NewPhotoPrompt {
  type: 'photo';
  photo: FilestackHandle;
  question: string;
}

type NewPrompt = NewCustomPrompt | NewTemplatePrompt | NewPhotoPrompt;

interface DefaultPageState {
  type: 'default';
}

interface CreatingPromptPageState {
  type: 'creating-prompt';
  promptType: 'template' | 'custom' | 'photo';
}

interface ConfirmRecordingModePageState {
  type: 'confirm-recording-mode';
  prompt: NewPrompt;
}

type PageState = DefaultPageState | CreatingPromptPageState | ConfirmRecordingModePageState;

interface InternalProjectRecordCustomizePageProps {
  projectId: string;
}

function InternalProjectRecordCustomizePage({ projectId }: InternalProjectRecordCustomizePageProps) {
  // Services
  const { projectCacheService, assetService } = useServices();
  const navigate = useNavigate();

  // Queries
  const projectQuery = useProjectQuery(projectId);

  // Local state
  const [pageState, setPageState] = useState<PageState>({ type: 'default' });

  const handleChoosePrompt = useCallback((promptType: CreatingPromptPageState['promptType']) => {
    setPageState({ type: 'creating-prompt', promptType });
  }, []);

  const [searchParams] = useSearchParams();
  const handleCreatePrompt = useCallback(
    async (recordingType: 'audio' | 'video') => {
      if (pageState.type !== 'confirm-recording-mode') {
        return;
      }

      try {
        const promptId = secureUuid();
        let createPromptPayload: CreatePromptPayload;

        switch (pageState.prompt.type) {
          case 'custom': {
            createPromptPayload = {
              id: promptId,
              status: PromptStatus.SENT,
              type: PromptType.TEXT,
              question: pageState.prompt.question,
              template: null,
            };
            break;
          }
          case 'template': {
            createPromptPayload = {
              id: promptId,
              status: PromptStatus.SENT,
              type: PromptType.TEXT,
              question: pageState.prompt.template.questions[0].text,
              template: {
                id: pageState.prompt.template.id,
                tagIds: [pageState.prompt.tag.id],
              },
            };
            break;
          }
          case 'photo': {
            const asset = await assetService.createFilestackAsset({
              handle: pageState.prompt.photo.handle,
              type: AssetType.IMAGE,
              entity: { type: EntityType.PROMPT, id: promptId },
            });
            createPromptPayload = {
              id: promptId,
              status: PromptStatus.SENT,
              type: PromptType.PHOTO,
              question: pageState.prompt.question,
              imagesIds: [asset.id],
            };
            break;
          }
        }

        await projectCacheService.createPrompts(projectId, [createPromptPayload]);
        navigate(
          getRecordingPath({ projectId, promptId, type: recordingType, catchup: true, customParams: searchParams }),
        );
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      }
    },
    [assetService, pageState, projectCacheService, projectId, navigate, searchParams],
  );

  const handleCloseChooseRecordingMode = useCallback(() => {
    if (pageState.type != 'confirm-recording-mode') {
      return;
    }

    setPageState({ type: 'creating-prompt', promptType: pageState.prompt.type });
  }, [pageState]);

  const handleBack = useCallback(() => {
    navigate(getProjectRecordPath(projectId, undefined, searchParams));
  }, [searchParams, navigate, projectId]);

  return (
    <StyledProjectRecordCustomizePage>
      <Header>
        <RMIconButton
          icon={faChevronLeft}
          size="xl"
          tooltip={{ label: 'Go back', position: 'bottom' }}
          backgroundColor="transparent"
          onClick={handleBack}
        />
        <RMText type="sans" size="m" color="on-surface-primary" bold>
          Choose your next story
        </RMText>
        <Hidden>
          <RMIconButton
            icon={faChevronLeft}
            size="xl"
            tooltip={{ label: 'Go back', position: 'bottom' }}
            backgroundColor="transparent"
          />
        </Hidden>
      </Header>
      <Content>
        <RMButtonList.Root>
          {projectQuery.data?.configuration.timePeriod === 'PAST' && (
            <>
              <RMButtonList.Item
                label="Write your own prompt"
                icon={faPen}
                onClick={() => handleChoosePrompt('custom')}
              />
              <RMButtonList.Item
                label="Choose a question"
                icon={askQuestionSrc}
                onClick={() => handleChoosePrompt('template')}
              />
            </>
          )}
          <RMButtonList.Item label="Upload a photo" icon={learnPhotoSrc} onClick={() => handleChoosePrompt('photo')} />
        </RMButtonList.Root>
      </Content>

      {/* Template */}
      {((pageState.type === 'creating-prompt' && pageState.promptType === 'template') ||
        (pageState.type === 'confirm-recording-mode' && pageState.prompt.type === 'template')) && (
        <PromptTemplatePickerDialogContainer
          onSelect={(template, tag) =>
            setPageState({
              type: 'confirm-recording-mode',
              prompt: {
                type: 'template',
                template,
                tag,
              },
            })
          }
          onClose={() => setPageState({ type: 'default' })}
        />
      )}
      {/* Custom */}
      {((pageState.type === 'creating-prompt' && pageState.promptType === 'custom') ||
        (pageState.type === 'confirm-recording-mode' && pageState.prompt.type === 'custom')) && (
        <PromptQuestionDialog
          onSubmit={(question) =>
            setPageState({
              type: 'confirm-recording-mode',
              prompt: {
                type: 'custom',
                question,
              },
            })
          }
          onClose={() => setPageState({ type: 'default' })}
        />
      )}
      {/* Photo */}
      {((pageState.type === 'creating-prompt' && pageState.promptType === 'photo') ||
        (pageState.type === 'confirm-recording-mode' && pageState.prompt.type === 'photo')) && (
        <PromptPhotoDialogContainer
          onSubmit={({ question, photo }) =>
            setPageState({
              type: 'confirm-recording-mode',
              prompt: {
                type: 'photo',
                photo,
                question,
              },
            })
          }
          onClose={() => setPageState({ type: 'default' })}
        />
      )}
      <RecordingModeDialog
        open={pageState.type === 'confirm-recording-mode'}
        onChooseMode={handleCreatePrompt}
        onClose={handleCloseChooseRecordingMode}
      />
    </StyledProjectRecordCustomizePage>
  );
}

export function ProjectRecordCustomizePage() {
  const params = useParams();

  return (
    <RementoPage type="default">
      <InternalProjectRecordCustomizePage projectId={params.projectId ?? ''} />
    </RementoPage>
  );
}
