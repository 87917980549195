import { useEffect, useRef } from 'react';

import { Video } from './RecordingCamera.styles';

interface RecordingCameraProps {
  mediaStream: MediaStream | null;
}

export function RecordingCamera({ mediaStream }: RecordingCameraProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.srcObject = mediaStream;
  }, [mediaStream]);

  return <Video ref={videoRef} autoPlay loop playsInline muted />;
}
