import { RMAvatarSharing } from '@/components/RMAvatarSharing';
import { useProjectMembers } from '@/modules/sharing/hooks/useProjectMembers';
import { ColorsTokens } from '@/styles/theme/colorTokens';

export interface ProjectMembersAvatarListContainerProps {
  projectId: string;
}

const MAX_AVATARS = 4;

export function ProjectMembersAvatarListContainer({ projectId }: ProjectMembersAvatarListContainerProps) {
  const members = useProjectMembers(projectId);

  const knownMembers = members?.filter((m) => m.type != 'invited' && m.initials != null);
  const avatarShowLimit = knownMembers && knownMembers.length > MAX_AVATARS ? MAX_AVATARS - 1 : MAX_AVATARS;
  const membersExceeded = knownMembers ? knownMembers.length - avatarShowLimit : 0;

  return (
    <RMAvatarSharing.Root>
      <>
        {knownMembers?.slice(0, avatarShowLimit)?.map((member) => {
          if (member.type === 'invited' || member?.initials == null) {
            return null;
          }
          return (
            <RMAvatarSharing.Item
              key={member.userId}
              size="small"
              border="surface"
              imageURL={member.avatarUrl}
              text={member.initials}
              tooltip={member.name?.full}
              backgroundColor={member.avatarColor}
              overlapping={true}
            />
          );
        })}
        {knownMembers && membersExceeded > 0 && (
          <RMAvatarSharing.Item
            size="small"
            border="surface"
            text={`${membersExceeded}+`}
            backgroundColor={ColorsTokens['on-secondary']}
            overlapping={true}
          />
        )}
      </>
    </RMAvatarSharing.Root>
  );
}
