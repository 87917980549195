import { styled } from '@linaria/react';
import { Swiper } from 'swiper/react';

export const StyledProjectRecordPage = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 480px;
  display: flex;
  flex-flow: column;
  padding-bottom: var(--spacing-xl);

  body[data-mobile='true'] & {
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
    overflow-x: hidden;
  }
`;

export const StyledSwiper = styled(Swiper)`
  flex: 1;
  width: 100%;
  max-height: 550px;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  flex: 0 0 6rem;
  margin: 0 calc(var(--spacing-md) * -1);

  body[data-mobile='true'] & {
    height: 4rem;
    flex: 0 0 4rem;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xl);
`;

export const PaginationButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-2);
  background-color: var(--surface-brightest);
  color: var(--primary);
  border-radius: 100%;
  cursor: pointer;

  :hover {
    background-color: var(--lighten-white-hover);
  }

  :active {
    background-color: var(--lighten-white-press);
  }

  :disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const CustomPromptCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const CustomPromptCardIcon = styled.img`
  width: 7.5rem;

  body[data-mobile='true'] & {
    width: 4rem;
  }
`;
