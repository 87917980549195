import { styled } from '@linaria/react';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
  z-index: 1;
`;

export const Message = styled.div`
  min-height: 12rem;

  span {
    font-family: var(--font-serif);
    font-size: 1.9375rem;
    color: var(--on-primary);
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  gap: var(--spacing-md);
`;

export const CloseButton = styled(RMIconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
`;

export const LearnMoreDialogContent = styled.div`
  pointer-events: auto;
  height: 100%;
  width: 100%;
`;
