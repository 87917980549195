import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
  height: 100%;
  width: 100%;
`;

export const ContainerWrapper = styled.div`
  width: 18rem;
`;

export const CenteredText = styled(RMText)`
  text-align: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;
