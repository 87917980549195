import { createStore, StoreApi, useStore } from 'zustand';

export interface RecordingPermissionState {
  type: 'permission';
}

export interface RecordingPermissionRejectedState {
  type: 'permission-rejected';
}

export interface RecordingReadyState {
  type: 'ready';
  mediaStream: MediaStream;
}

export interface RecordingRecordState {
  type: 'record';
  mediaStream: MediaStream;
}

export interface RecordingUploadingState {
  type: 'uploading';
  recordingDuration: number;
  totalRecordedTime: number;
}

export interface RecordingCelebrationState {
  type: 'celebration';
  totalRecordedTime: number;
}

export type RecordingState =
  | RecordingPermissionState
  | RecordingPermissionRejectedState
  | RecordingReadyState
  | RecordingRecordState
  | RecordingUploadingState
  | RecordingCelebrationState;

export type RecordingStore = StoreApi<RecordingState>;

export function createRecordingStore(): RecordingStore {
  return createStore<RecordingState>(() => ({ type: 'permission' }));
}

export function setRecordingState(store: RecordingStore, state: RecordingState): void {
  store.setState(state, true);
}

export function getRecordingState(store: RecordingStore): RecordingState {
  return store.getState();
}

export function useRecordingState(store: RecordingStore): RecordingState {
  return useStore(store);
}
