import { createStore as zustandCreateStore, StoreApi } from 'zustand/vanilla';

import { logger } from '@/logger';

export type State = {
  type: string;
};

export type Store<T> = StoreApi<T>;

export type SubscribeOptions = {
  immediate?: boolean;
  once?: boolean;
};
export type SubscribeSelector<T, S> = (state: T) => S;
export type SubscribeFilter<T> = (state: T, prevState: T) => boolean;
export type SubscribeCallback<S, PV = S> = (state: S, prevState: PV) => void;
export type Unsubscribe = () => void;

export function createStore<T extends State>(initialValue: T) {
  return zustandCreateStore<T>(() => initialValue);
}

export function setState<T extends State>(store: Store<T>, state: T): void {
  store.setState(state, true);
}

export function getState<T extends State>(store: Store<T>): T {
  return store.getState();
}

export function subscribeWithSelector<S extends State, U>(
  store: Store<S>,
  selector: SubscribeSelector<S, U>,
  filter: SubscribeFilter<U>,
  callback: SubscribeCallback<U>,
  options?: SubscribeOptions,
): Unsubscribe {
  if (options?.immediate === true) {
    const state = store.getState();
    const selection = selector(state);
    const matchesFilter = filter(selection, selection);

    if (matchesFilter) {
      setTimeout(() => {
        callback(selection, selection);
      }, 0);

      if (options.once === true) {
        // We don't need to subscribe to the store if the callback has already been fired.
        return () => {
          // No-op
        };
      }
    }
  }

  const unsubscribe = store.subscribe((state, prevState) => {
    const selection = selector(state);
    const prevSelection = selector(prevState);
    const matchesFilter = filter(selection, prevSelection);

    if (matchesFilter) {
      setTimeout(() => {
        callback(selection, prevSelection);
      }, 0);

      if (options?.once === true) {
        unsubscribe();
      }
    }
  });

  return unsubscribe;
}

export function subscribe<T extends State>(
  store: Store<T>,
  callback: SubscribeCallback<T>,
  options?: SubscribeOptions,
): Unsubscribe {
  return subscribeWithSelector(
    store,
    (state) => state,
    () => true,
    callback,
    options,
  );
}

export function subscribeType<S extends State, T extends S['type']>(
  store: Store<S>,
  subscribedType: T,
  callback: SubscribeCallback<Extract<S, { type: T }>, S>,
  options?: SubscribeOptions,
): Unsubscribe {
  return subscribeWithSelector(
    store,
    (state) => state,
    (state) => state.type === subscribedType,
    // TODO - It's probably possible to improve the typing in the subscribeWithSelector hook
    (state, prevState) => callback(state as Extract<S, { type: T }>, prevState),
    options,
  );
}

export async function wait<T extends State>(
  store: Store<T>,
  filter: (value: T) => boolean,
  timeout?: number,
): Promise<T> {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;
  let cancelSubscribe: Unsubscribe | null = null;

  const waitPromise = new Promise<T>((resolve) => {
    const currentState = getState(store);
    if (filter(currentState)) {
      resolve(currentState);
      return;
    }

    cancelSubscribe = subscribe(store, (newState) => {
      if (filter(newState)) {
        resolve(newState);
      }
    });
  }).finally(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (cancelSubscribe) {
      cancelSubscribe();
      cancelSubscribe = null;
    }
  });

  if (timeout === undefined) {
    return waitPromise;
  }

  return Promise.race([
    waitPromise,
    new Promise<T>((resolve, reject) => {
      timeoutId = setTimeout(() => {
        if (cancelSubscribe) {
          cancelSubscribe();
          cancelSubscribe = null;
        }
        timeoutId = null;
        reject(new Error('Wait timeout'));
      }, timeout);
    }),
  ]);
}

export async function waitForType<T extends State>(store: Store<T>, type: T['type'], timeout?: number): Promise<T> {
  return wait(store, (state) => state.type === type, timeout);
}

export abstract class StateMachine<S extends State> {
  protected readonly store: Store<S>;

  constructor(initialState: S) {
    this.store = createStore(initialState);
  }

  protected setState(state: S): S {
    logger.info(`[${this.constructor.name}] New state - ${state.type}`, state);
    setState(this.store, state);
    return state;
  }

  public getState(): S {
    return getState(this.store);
  }

  public subscribe(callback: SubscribeCallback<S>, options?: SubscribeOptions): Unsubscribe {
    return subscribe(this.store, callback, options);
  }

  public subscribeWithSelector<U>(
    selector: SubscribeSelector<S, U>,
    filter: SubscribeFilter<U>,
    callback: SubscribeCallback<U>,
    options?: SubscribeOptions,
  ): Unsubscribe {
    return subscribeWithSelector(this.store, selector, filter, callback, options);
  }

  public subscribeType<T extends S['type']>(
    type: T,
    callback: SubscribeCallback<Extract<S, { type: T }>, S>,
    options?: SubscribeOptions,
  ): Unsubscribe {
    return subscribeType(this.store, type, callback, options);
  }

  public wait(filter: (value: S) => boolean, timeout?: number): Promise<S> {
    return wait(this.store, filter, timeout);
  }

  public waitForType(type: S['type'], timeout?: number): Promise<S> {
    return waitForType(this.store, type, timeout);
  }
}
