import { State } from './state-machine';

type MatcherState<S extends State, T extends S['type']> = Extract<S, { type: T }>;
type MatcherCallback<S extends State, T extends S['type'], R> = (state: MatcherState<S, T>) => R;
type MatcherCase<S extends State, T extends State['type'], R> = [T, MatcherCallback<S, T, R>];

export class StateMatcher<S extends State, T extends State['type'] = never, R = never> {
  protected cases: Array<MatcherCase<S, T, R>> = [];

  constructor(private state: S) {}

  withType<LocalT extends S['type'], LocalR>(
    type: Exclude<LocalT, T>,
    callback: MatcherCallback<S, LocalT, LocalR>,
  ): StateMatcher<S, T | LocalT, R | LocalR> {
    const matcher = this as StateMatcher<S, T | LocalT, R | LocalR>;
    matcher.cases.push([type, callback as MatcherCallback<S, T | LocalT, R | LocalR>]);
    return matcher;
  }

  otherwise<LocalR>(callback: MatcherCallback<S, Exclude<S['type'], T>, LocalR>): R | LocalR {
    for (const [caseType, caseCallback] of this.cases) {
      if (caseType === this.state.type) return caseCallback(this.state as MatcherState<S, typeof caseType>);
    }
    return callback(this.state as MatcherState<S, Exclude<S['type'], T>>);
  }

  static returnState<S extends State>(state: S): S {
    return state;
  }

  static returnNull<S extends State>(_state: S): null {
    return null;
  }

  static throwInvalidState<S extends State>(state: S): never {
    throw new Error(`Invalid state ${state.type}`);
  }
}

export function matchState<S extends State>(state: S): StateMatcher<S> {
  return new StateMatcher<S>(state);
}
