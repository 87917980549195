import { AnimatePresence } from 'framer-motion';

import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { ColorsTokens } from '@/styles/theme/colorTokens';

import { Banner } from './RecordingBanner.styles';

interface RecordingBanner {
  message: string;
  textColor: keyof typeof ColorsTokens;
  bgColor: keyof typeof ColorsTokens;
}

export function RecordingBanner({ message, textColor, bgColor }: RecordingBanner) {
  const isMobile = useIsMobileViewport();

  return (
    <>
      <AnimatePresence>
        <Banner
          initial={{ translateY: '-100%' }}
          animate={{ translateY: '0%', transition: { duration: 0.25, ease: 'easeOut' } }}
          exit={{ translateY: '-100%', transition: { duration: 0.25, ease: 'easeIn' } }}
          data-bgcolor={bgColor}
        >
          <RMText type="sans" size={isMobile ? 'xs' : 's'} color={textColor} bold>
            {message}
          </RMText>
        </Banner>
      </AnimatePresence>
    </>
  );
}
