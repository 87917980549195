import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const MediaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
`;

export const WaveFormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Video = styled.video`
  height: 85px;
  width: 85px;
  object-fit: cover;
  border-radius: var(--radius-full);
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: var(--spacing-md);
  align-items: center;
`;

export const SelectIcon = styled(FontAwesomeIcon)`
  color: var(--on-surface-secondary);
  width: 32px;
`;
