import { Subscription } from './event-emitter';

export class SubscriptionGroup {
  // TODO - Use same API
  private subscriptions: (Subscription | (() => void))[] = [];

  add(subscription: Subscription | (() => void)): void {
    this.subscriptions.push(subscription);
  }

  destroy(): void {
    for (const subscription of this.subscriptions) {
      if (typeof subscription === 'object') {
        subscription.remove();
      } else {
        subscription();
      }
    }
    this.subscriptions = [];
  }
}
