import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faRedo } from '@fortawesome/pro-regular-svg-icons';
import { EntityType } from '@remento/types/entity';
import { PromptType } from '@remento/types/project';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { toast } from '@/components/RMToast/RMToast';
import { useAsyncEffect } from '@/hooks';
import { logger } from '@/logger';
import { ProjectMembersAvatarListContainer } from '@/modules/recording/containers/ProjectMembersAvatarList.container';
import { getRecordingFinishPath, getRecordingTypeSelectionPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useCurrentUrlWithTokens } from '@/services/api/authorization';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, usePromptFirstQuestionQuery, usePromptQuery } from '@/services/api/project';
import { hasIndexedDbSupport } from '@/utils/hasIndexedDbSuport';
import { secureUuid } from '@/utils/uuid';

import {
  InactiveMediaStreamError,
  MissingMediaTrackError,
  RecordingType,
  useInterviewManager,
  useLastInterviewSessionsByPromptId,
} from '../../conversation-recorder/interview';
import { RecordCelebration } from '../components/RecordCelebration/RecordCelebration';
import { RecordingDeviceModal } from '../components/RecordingDeviceModal/RecordingDeviceModal';
import { RecordPermission } from '../components/RecordPermission/RecordPermission';
import { RecordPermissionRejected } from '../components/RecordPermissionRejected/RecordPermissionRejected';
import { RecordReady } from '../components/RecordReady/RecordReady';
import { useAudioStreamValid } from '../hooks/audio-analyzer';
import { useRecorderName } from '../hooks/record-person-name';
import {
  createRecordingStore,
  getRecordingState,
  setRecordingState,
  useRecordingState,
} from '../states/recording.state';
import { listenToPermissionChanges } from '../utils/permission-listener';

import { RecordingStepContainer } from './RecordingStep.container';
import { RecordUploadingContainer } from './RecordUploading.container';

function getUserMedia(
  audio: boolean | MediaTrackConstraints,
  video: boolean | MediaTrackConstraints,
): Promise<MediaStream> {
  return navigator.mediaDevices.getUserMedia({ audio, video });
}

export interface RecordContainerProps {
  projectId: string;
  promptId: string;
  recordingType: RecordingType;
}

function formatTime(seconds: number) {
  const date = new Date(seconds * 1000);
  const formatter = new Intl.DateTimeFormat('en', { minute: '2-digit', second: '2-digit' });
  const formatted = formatter.format(date);

  return formatted.startsWith('0') ? formatted.substring(1, formatted.length) : formatted;
}

export function RecordContainer({ projectId, promptId, recordingType }: RecordContainerProps) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  // Services
  const {
    analyticsService,
    storytellingAnalyticsService,
    webappAnalyticsService,
    storyCacheService,
    recordingSessionRepository,
    entityCacheManagerService,
  } = useServices();
  const interviewManager = useInterviewManager();

  // Entities
  const user = useUser();
  const projectQuery = useProjectQuery(projectId);
  const promptQuery = usePromptQuery(promptId);
  const questionQuery = usePromptFirstQuestionQuery(promptId);
  const recorderName = useRecorderName(projectId);
  const hostPersonQuery = usePersonQuery(promptQuery.data?.requesterPersonId);

  // Analytics state
  const persona = useMemo(() => {
    return promptQuery.data?.requesterPersonId === user?.personId ? 'sender' : 'recipient';
  }, [promptQuery.data?.requesterPersonId, user?.personId]);

  // State
  const recordingStore = useMemo(() => createRecordingStore(), []);
  const recordingState = useRecordingState(recordingStore);
  const [askingPermission, setAskingPermission] = useState(false);
  const [loadingPermission, setLoadingPermission] = useState(true);

  // Sessions
  const { lastSession } = useLastInterviewSessionsByPromptId(params.promptId ?? null);
  const lastSessionTotalDuration = lastSession ? lastSession.duration / 1000 - lastSession.promptSentTimestamp : 0;
  const lastSessionTotalDurationFormatted = useMemo(() => {
    return lastSessionTotalDuration ? formatTime(lastSessionTotalDuration) : null;
  }, [lastSessionTotalDuration]);

  const [lastSessionDialogOpen, setLastSessionDialogOpen] = useState(false);
  const [resumeLastSession, setResumeLastSession] = useState<boolean>(false);

  const navigate = useNavigate();

  let recorderPersonId = searchParams.get('recorder-person-id');
  const recorderUserId = searchParams.get('recorder-user-id');
  if (recorderPersonId == null && recorderUserId == null) {
    recorderPersonId = user?.personId ?? null;
  }

  const [devicesModalOpen, setDevicesModalOpen] = useState(false);
  const isMediaStreamValid = useAudioStreamValid(
    useMemo(() => {
      if (recordingState.type === 'ready') {
        return recordingState.mediaStream;
      }
      return null;
    }, [recordingState]),
  );

  const [isBrowserNotSupported, setIsBrowserNotSupported] = useState(false);
  const recordingLink = useCurrentUrlWithTokens();

  const handlePermissionsGranted = useCallback(async () => {
    try {
      const mediaStream = await getUserMedia(true, recordingType === 'video');
      setLoadingPermission(false);
      setRecordingState(recordingStore, {
        type: 'ready',
        mediaStream,
      });
      setAskingPermission(false);
    } catch (error) {
      logger.warn('REQUEST_PERMISSIONS_ERROR', { error, message: (error as Error).message });
      setRecordingState(recordingStore, { type: 'permission-rejected' });
      storytellingAnalyticsService.onStorytellingInputPermissionDeclined(persona, recordingType, referrer);
      setAskingPermission(false);
    }
  }, [persona, recordingStore, recordingType, storytellingAnalyticsService, referrer]);

  const handleRequestPermissions = useCallback(async () => {
    // Load for 2500ms
    setAskingPermission(true);
    await new Promise((resolve) => setTimeout(resolve, 2500));
    await handlePermissionsGranted();
  }, [handlePermissionsGranted]);

  const handlePermissionRejectedGoBack = useCallback(() => {
    navigate(getRecordingTypeSelectionPath(params.projectId ?? '', params.promptId ?? ''));
  }, [navigate, params.promptId, params.projectId]);

  const handleChangeDevices = useCallback(
    (newMediaStream: MediaStream) => {
      const state = getRecordingState(recordingStore);

      // @Todo: Implement change device in 'record' state
      if (state.type !== 'ready') {
        throw new Error('Invalid state to change the devices');
      }
      setRecordingState(recordingStore, {
        type: state.type,
        mediaStream: newMediaStream,
      });

      setDevicesModalOpen(false);
      storytellingAnalyticsService.onStorytellingInputChanged(persona, recordingType, referrer);
    },
    [persona, recordingStore, recordingType, storytellingAnalyticsService, referrer],
  );

  const handleStartRecording = useCallback(async () => {
    const prompt = promptQuery.data;
    const question = questionQuery.data;
    if (!prompt || !question) {
      return;
    }

    // If the stream is not valid, open the change device modal
    if (isMediaStreamValid === false) {
      setDevicesModalOpen(true);
      return;
    }

    const state = getRecordingState(recordingStore);
    if (state.type !== 'ready') {
      throw new Error('Invalid state to start the recording');
    }

    const lastSessionId = resumeLastSession ? lastSession?.sessionId : null;
    const sessionId = lastSessionId ?? secureUuid();

    try {
      let mediaStream: MediaStream = state.mediaStream;

      if (state.mediaStream.active === false) {
        // Try to recover om a inactive media stream.
        const audioDeviceId = mediaStream.getAudioTracks()[0]?.getSettings().deviceId ?? null;
        const videoDeviceId = mediaStream.getVideoTracks()[0]?.getSettings().deviceId ?? null;
        if (recordingType === 'audio' && audioDeviceId !== null) {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: false,
            audio: { deviceId: audioDeviceId },
          });
        } else if (recordingType === 'video' && audioDeviceId !== null && videoDeviceId !== null) {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: videoDeviceId },
            audio: { deviceId: audioDeviceId },
          });
        }
      }

      await interviewManager.initialize({
        sessionId,
        imageId: prompt.type === PromptType.PHOTO ? prompt.imagesIds[0] : null,
        promptId: prompt.id,
        promptQuestion: question.text ?? '',
        mediaStream,
        type: recordingType,
        recorderUserRuid: analyticsService.getRuid(),
        recorderUserId,
        recorderPersonId,
        language: navigator.language,
      });

      setRecordingState(recordingStore, {
        type: 'record',
        mediaStream: mediaStream,
      });
    } catch (error) {
      if (error instanceof InactiveMediaStreamError || error instanceof MissingMediaTrackError) {
        toast(
          'The chosen device appears to be malfunctioning. Please try refreshing the page or tapping the gear icon in the top right corner to select an alternative device.',
          'root-toast',
          'error',
        );
      } else {
        toast('An unexpected error occurred. Please try refreshing the page.', 'root-toast', 'error');
      }
    }
  }, [
    analyticsService,
    interviewManager,
    isMediaStreamValid,
    lastSession,
    promptQuery.data,
    questionQuery.data,
    recorderUserId,
    recorderPersonId,
    recordingStore,
    recordingType,
    resumeLastSession,
  ]);

  const handleFinishRecording = useCallback(
    (recordingDuration: number) => {
      const state = getRecordingState(recordingStore);
      if (state.type !== 'record' && state.type !== 'ready') {
        throw new Error('Invalid state to finish the recording');
      }
      state.mediaStream.getTracks().forEach((t) => t.stop());

      const totalRecordedTime = recordingDuration + (projectQuery.data?.statistics.storiesRecordedTime ?? 0);
      setRecordingState(recordingStore, { type: 'uploading', recordingDuration, totalRecordedTime });
    },
    [recordingStore, projectQuery.data?.statistics.storiesRecordedTime],
  );

  const handleFinishUpload = useCallback(() => {
    const state = getRecordingState(recordingStore);
    if (state.type !== 'uploading') {
      throw new Error('Invalid state to finish the upload');
    }

    storytellingAnalyticsService.onStorytellingRecordingSucceeded(
      persona,
      recordingType,
      state.recordingDuration,
      referrer,
    );
    setRecordingState(recordingStore, { type: 'celebration', totalRecordedTime: state.totalRecordedTime });

    // Invalidate the story record data from the cache after uploading the recording
    storyCacheService.invalidateStoryRecordByPromptIdCache(promptId);
    entityCacheManagerService.invalidateCollection(EntityType.STORY, { projectId });
    entityCacheManagerService.invalidateCollection(EntityType.PROMPT, { projectId });
  }, [
    recordingStore,
    storytellingAnalyticsService,
    persona,
    recordingType,
    referrer,
    storyCacheService,
    promptId,
    entityCacheManagerService,
    projectId,
  ]);

  const handleFinishCelebration = useCallback(() => {
    const state = getRecordingState(recordingStore);
    if (state.type !== 'celebration') {
      throw new Error('Invalid state to finish the upload');
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');

    recordingSessionRepository.setTotalProjectRecordingTime(state.totalRecordedTime);
    navigate(getRecordingFinishPath(projectId, newSearchParams));
  }, [recordingStore, projectId, navigate, recordingSessionRepository, searchParams]);

  // Last session
  const recoveringFinishedRecordingRef = useRef(false);

  useEffect(() => {
    if (lastSession == null || projectQuery.data == null) {
      return;
    }

    if (lastSession.finished === false) {
      setLastSessionDialogOpen(true);
      return;
    }

    const recoverFinishedRecording = async () => {
      if (recoveringFinishedRecordingRef.current) {
        return;
      }

      recoveringFinishedRecordingRef.current = true;

      const totalRecordedTime =
        lastSessionTotalDuration +
        (recordingSessionRepository.getTotalProjectRecordingTime() ??
          projectQuery.data.statistics.storiesRecordedTime ??
          0);
      setRecordingState(recordingStore, {
        type: 'uploading',
        recordingDuration: lastSessionTotalDuration,
        totalRecordedTime,
      });
      await interviewManager.recover(lastSession.sessionId);
    };

    recoverFinishedRecording();
  }, [
    interviewManager,
    lastSession,
    lastSessionTotalDuration,
    projectQuery.data,
    recordingSessionRepository,
    recordingStore,
  ]);

  const handleSubmitLastSession = useCallback(async () => {
    if (lastSession == null) {
      return;
    }

    await interviewManager.recover(lastSession.sessionId);
    setLastSessionDialogOpen(false);
    handleFinishRecording(lastSessionTotalDuration);
    storytellingAnalyticsService.onStorytellingRecordingFinished(
      persona,
      recordingType,
      lastSessionTotalDuration,
      referrer,
    );
  }, [
    handleFinishRecording,
    interviewManager,
    lastSession,
    lastSessionTotalDuration,
    persona,
    recordingType,
    storytellingAnalyticsService,
    referrer,
  ]);

  const handleResumeLastSession = useCallback(() => {
    setLastSessionDialogOpen(false);
    setResumeLastSession(true);
  }, []);

  const handleDeleteLastSession = useCallback(async () => {
    if (lastSession == null) {
      return;
    }

    setLastSessionDialogOpen(false);
    setResumeLastSession(false);
    await interviewManager.deleteSession(lastSession.sessionId);
  }, [interviewManager, lastSession]);

  const handleBack = useCallback(() => {
    // The goal is to preserve all URL search parameters except the recording type which will be selected
    // in the screen they're navigating to
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');

    navigate(getRecordingTypeSelectionPath(projectId, promptId, newSearchParams));
  }, [navigate, projectId, promptId, searchParams]);

  // Stop all the streams when leaving
  useEffect(() => {
    return () => {
      const state = getRecordingState(recordingStore);
      if (state.type !== 'record' && state.type !== 'ready') {
        return;
      }
      state.mediaStream.getTracks().forEach((t) => t.stop());
    };
  }, [recordingStore]);

  useEffect(() => {
    const checkIncognito = async () => {
      const supportsIndexedDb = await hasIndexedDbSupport();
      if (!supportsIndexedDb) {
        setIsBrowserNotSupported(true);
      }
    };

    checkIncognito();
  }, []);

  // check permissions
  useAsyncEffect(
    async (checkpoint) => {
      if (recordingState.type !== 'permission') {
        return;
      }

      // If there's a session that is already finished but not uploaded, don't do anything.
      // THe other useEffect will change the state.
      if (lastSession != null && lastSession.finished) {
        return;
      }

      // The permissions api is not supported in some browsers (for example, IOS 15.x).
      // In that case, we should ask for user permissions every time.
      if (navigator.permissions == null) {
        setLoadingPermission(false);
        return;
      }

      const audioPermission = await navigator.permissions.query({ name: 'microphone' as PermissionName }).catch(() => {
        // The microphone permission query is not available in some browsers (firefox)
        return null;
      });
      checkpoint();

      let cameraPermission: PermissionStatus | null = null;
      if (recordingType === 'video') {
        cameraPermission = await navigator.permissions.query({ name: 'camera' as PermissionName }).catch(() => {
          // The camera permission query is not available in some browsers (firefox)
          return null;
        });
        checkpoint();
      }

      // If the permission api is no available. we will ne be able to check if the user have permissions or not.
      // So we can just set loading to false and pretend the user has not been asked yet.
      if (audioPermission === null) {
        setLoadingPermission(false);
        return;
      }
      if (recordingType === 'video' && cameraPermission === null) {
        setLoadingPermission(false);
        return;
      }

      // Check the permission
      if (audioPermission.state === 'granted' && (recordingType === 'audio' || cameraPermission?.state === 'granted')) {
        handlePermissionsGranted();
        return;
      }
      if (audioPermission?.state === 'denied' || cameraPermission?.state === 'denied') {
        setRecordingState(recordingStore, { type: 'permission-rejected' });
        return;
      }

      setLoadingPermission(false);
    },
    [
      handleRequestPermissions,
      recordingType,
      handlePermissionsGranted,
      recordingStore,
      recordingState.type,
      lastSession,
    ],
  );

  // Analytics
  useEffect(() => {
    return listenToPermissionChanges(webappAnalyticsService);
  }, [webappAnalyticsService]);

  switch (recordingState.type) {
    case 'permission':
      return (
        <RecordPermission
          type={recordingType}
          host={hostPersonQuery.data?.name?.first ?? 'Someone'}
          browserNotSupported={isBrowserNotSupported}
          link={recordingLink}
          onRequestPermissions={() => handleRequestPermissions()}
          askingPermission={askingPermission}
          loadingPermission={loadingPermission}
          onBack={handleBack}
        />
      );
    case 'permission-rejected':
      return <RecordPermissionRejected type={recordingType} onGoBack={handlePermissionRejectedGoBack} />;
    case 'ready':
      return (
        <>
          <RecordReady
            type={recordingType}
            name={recorderName}
            mediaStream={recordingState.mediaStream}
            onContinue={handleStartRecording}
            onOpenDevicesModal={() => setDevicesModalOpen(true)}
            onBack={handleBack}
          />
          <RecordingDeviceModal
            open={devicesModalOpen}
            type={recordingType}
            mediaStream={recordingState.mediaStream}
            onChangeDevices={handleChangeDevices}
          />
          <RMConfirmationModal
            open={lastSessionDialogOpen}
            type="primary"
            title="Submit your last recording"
            message={`You have not yet submitted your recording for “${questionQuery.data?.text}${
              lastSessionTotalDuration > 0 ? ` (${lastSessionTotalDurationFormatted})` : ''
            }”. We have saved your progress, but we cannot provide a preview. How would you like to proceed?`}
            confirmLabel="Submit"
            cancelLabel="Resume"
            onConfirm={handleSubmitLastSession}
            onCancel={handleResumeLastSession}
            leftButtons={
              <RMButton leftIcon={faRedo} onClick={handleDeleteLastSession} background="transparent">
                Start over
              </RMButton>
            }
            onClose={null}
          />
        </>
      );
    case 'record':
      return (
        <>
          <RecordingStepContainer
            promptId={promptId}
            type={recordingType}
            persona={persona}
            mediaStream={recordingState.mediaStream}
            onFinish={handleFinishRecording}
            RecordingDeviceButton={null} // @Todo: while we dont have support to change device in record state
            resumingSession={resumeLastSession ? lastSession : null}
            onBack={handleBack}
          />
        </>
      );
    case 'uploading':
      return <RecordUploadingContainer onFinish={handleFinishUpload} />;
    case 'celebration':
      return (
        <RecordCelebration
          onFinishCelebration={handleFinishCelebration}
          ProjectMembersAvatarList={<ProjectMembersAvatarListContainer projectId={projectId} />}
        />
      );
  }
}
