import RecordRTC from 'recordrtc';

import { logger } from '@/logger';

import { EventEmitter, Subscription } from '../event-emitter';
import { RecorderMediaStream } from '../media-stream/media-stream';
import { RecorderMediaStreamStateType } from '../media-stream/media-stream.types';
import { StateMachine } from '../state-machine';
import { matchState, StateMatcher } from '../state-matcher';

import { MediaRecorderState, MediaRecorderStateType, RecorderOptions } from './media-recorder.types';

type MediaRecorderEventMap = {
  dataAvailable: Blob;
};

export class MediaRecorder extends StateMachine<MediaRecorderState> {
  private readonly events = new EventEmitter<MediaRecorderEventMap>();

  constructor() {
    super({
      type: MediaRecorderStateType.Empty,
    });
  }

  private finishInitialize() {
    // Validate current state
    const state = this.getState();
    if (state.type !== MediaRecorderStateType.Initializing) {
      throw new Error('The MediaRecorder has already been initialized');
    }

    // Validate stream state
    const streamState = state.stream.getState();
    if (streamState.type !== RecorderMediaStreamStateType.Streaming) {
      throw new Error('The MediaStream is not ready');
    }

    // Update current state
    this.setState({
      type: MediaRecorderStateType.Ready,
      stream: state.stream,
      recorderOptions: state.recorderOptions,
    });

    // Register listeners
    // The media stream guarantees that it will only ever go once from Streaming to Finished
    state.stream.subscribeType(RecorderMediaStreamStateType.Finished, () => this.stop(), { once: true });
  }

  /**
   * This accepts a recorder media stream in the empty or streaming state
   */
  initialize(stream: RecorderMediaStream, recorderOptions: RecorderOptions): void {
    // Validate current state
    const state = matchState(this.getState())
      .withType(MediaRecorderStateType.Empty, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Initializing, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Ready, StateMatcher.returnState)
      .otherwise(() => {
        throw new Error('The MediaRecorder has already been initialized');
      });

    if (state.type === MediaRecorderStateType.Initializing || state.type === MediaRecorderStateType.Ready) {
      if (stream === state.stream) {
        console.warn('The MediaRecorder is initialized/initializing with the provided MediaStream');
        return;
      }
      throw new Error('The MediaRecorder has already been initialized');
    }

    // Validate stream state
    matchState(stream.getState())
      .withType(RecorderMediaStreamStateType.Empty, StateMatcher.returnState)
      .withType(RecorderMediaStreamStateType.Streaming, StateMatcher.returnState)
      .otherwise(() => {
        throw new Error('The MediaStream is not in a valid state');
      });

    // Update current state
    this.setState({
      type: MediaRecorderStateType.Initializing,
      stream,
      recorderOptions,
    });

    // Register listeners
    // The media stream guarantees that it will only ever go once from Empty to Streaming
    stream.subscribeType(RecorderMediaStreamStateType.Streaming, () => this.finishInitialize(), {
      once: true,
      immediate: true,
    });
  }

  private createRecorder(): RecordRTC {
    const state = matchState(this.getState())
      .withType(MediaRecorderStateType.Ready, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Paused, StateMatcher.returnState)
      .otherwise(() => {
        throw new Error('The MediaRecorder is not in a valid state to create a MediaRecorder');
      });

    // Validate stream state
    const streamState = state.stream.getState();
    if (streamState.type !== RecorderMediaStreamStateType.Streaming) {
      throw new Error('The MediaStream is not ready');
    }

    const recorder = new RecordRTC(streamState.stream, {
      ...state.recorderOptions,
      ondataavailable: (data) => {
        logger.debug('MEDIA_RECORDER.RECORDRTC.DATA', { size: data.size, type: data.type });

        // Ignore events fired by RecordRTC after the media recorder has already been stopped.
        const isStopped = matchState(this.getState())
          .withType(MediaRecorderStateType.Paused, () => true)
          .withType(MediaRecorderStateType.Finished, () => true)
          .otherwise(() => false);
        if (isStopped === true) {
          logger.warn('MEDIA_RECORDER.RECORDRTC.DATA_RECEIVED_WHILE_STOPPED', { size: data.size, type: data.type });
          return;
        }

        this.events.emit('dataAvailable', data, true);
      },
    });

    return recorder;
  }

  start(): void {
    // Validate current state
    const state = matchState(this.getState())
      .withType(MediaRecorderStateType.Ready, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Paused, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Recording, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The MediaRecorder is not ready or paused');
      });
    if (state === null) {
      console.warn('The MediaRecorder is already recording');
      return;
    }

    // Start the recording
    const recorder = this.createRecorder();
    recorder.startRecording();

    // Update current state
    this.setState({
      type: MediaRecorderStateType.Recording,
      stream: state.stream,
      recorder: recorder,
      recorderOptions: state.recorderOptions,
      duration: state.type === MediaRecorderStateType.Paused ? state.duration : 0,
      pauseCount: state.type === MediaRecorderStateType.Paused ? state.pauseCount : 0,
      startTimestamp: Date.now(),
    });
  }

  async pause(): Promise<void> {
    // Validate current state
    const state = matchState(this.getState())
      .withType(MediaRecorderStateType.Recording, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Pausing, StateMatcher.returnNull)
      .withType(MediaRecorderStateType.Paused, StateMatcher.returnNull)
      .withType(MediaRecorderStateType.Finishing, StateMatcher.returnNull)
      .withType(MediaRecorderStateType.Finished, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The MediaRecorder is not in valid state to pause');
      });

    if (state === null) {
      console.warn('The MediaRecorder is already pausing/paused or finishing/finished');
      return;
    }

    // Update current state
    this.setState({
      type: MediaRecorderStateType.Pausing,
      stream: state.stream,
      recorder: state.recorder,
      recorderOptions: state.recorderOptions,
      duration: state.duration,
      pauseCount: state.pauseCount,
      startTimestamp: state.startTimestamp,
    });

    // Pause the recording
    logger.debug('MEDIA_RECORDER.RECORDRTC.STOP');
    state.recorder.stopRecording(() => {
      logger.debug('MEDIA_RECORDER.RECORDRTC.STOPPED');

      // Destroy the recorder
      state.recorder.destroy();

      const duration = state.duration + Date.now() - state.startTimestamp;

      // RecordRTC does not wait for the stop event to be fired to call this callback,
      // So if for some reason the stopping process takes longer than expected, a data available event
      // might be fired after changing the state to Paused and it will cause out of order message issues.
      // Wait 500ms before changing the state to Paused to ensure all data events have been fired.
      setTimeout(() => {
        // Update current state
        this.setState({
          type: MediaRecorderStateType.Paused,
          stream: state.stream,
          recorderOptions: state.recorderOptions,
          duration,
          pauseCount: state.pauseCount + 1,
        });
      }, 500);
    });

    // Wait until it's actually paused to resolve the promise
    await this.waitForType(MediaRecorderStateType.Paused);
  }

  async stop(): Promise<void> {
    // Validate current state
    const state = matchState(this.getState())
      .withType(MediaRecorderStateType.Recording, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Pausing, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Paused, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Ready, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Empty, StateMatcher.returnState)
      .withType(MediaRecorderStateType.Finishing, StateMatcher.returnNull)
      .withType(MediaRecorderStateType.Finished, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The MediaRecorder is not in valid state to pause');
      });

    if (state === null) {
      console.warn('The MediaRecorder is already finishing or finished');
      return;
    }

    if (state.type === MediaRecorderStateType.Empty || state.type === MediaRecorderStateType.Ready) {
      this.setState({
        type: MediaRecorderStateType.Finished,
        duration: 0,
      });
      return;
    }

    // If the recording is paused or pausing, wait until it's paused and transition to the finished state directly.
    if (state.type === MediaRecorderStateType.Paused || state.type === MediaRecorderStateType.Pausing) {
      if (state.type === MediaRecorderStateType.Pausing) {
        await this.waitForType(MediaRecorderStateType.Paused);
      }

      const pausedState = matchState(this.getState())
        .withType(MediaRecorderStateType.Paused, StateMatcher.returnState)
        .otherwise(() => {
          throw new Error('The MediaRecorder is not paused');
        });

      // Update current state
      this.setState({
        type: MediaRecorderStateType.Finished,
        duration: pausedState.duration,
      });
      return;
    }

    this.setState({
      type: MediaRecorderStateType.Finishing,
      duration: state.duration,
      recorder: state.recorder,
    });

    logger.debug('MEDIA_RECORDER.RECORDRTC.STOP');
    state.recorder.stopRecording(() => {
      logger.debug('MEDIA_RECORDER.RECORDRTC.STOPPED');

      // Destroy the recorder
      state.recorder.destroy();

      // Update current state
      this.setState({
        type: MediaRecorderStateType.Finished,
        duration: state.duration + Date.now() - state.startTimestamp,
      });
    });

    // Wait until it's actually finished to resolve the promise
    await this.waitForType(MediaRecorderStateType.Finished);
  }

  onDataAvailable(callback: (data: Blob) => void): Subscription {
    return this.events.addListener('dataAvailable', callback);
  }

  destroy(): void {
    logger.info('MEDIA_RECORDER.DESTROY');

    const state = this.getState();
    switch (state.type) {
      case MediaRecorderStateType.Finishing:
      case MediaRecorderStateType.Finished:
      case MediaRecorderStateType.Destroyed:
      case MediaRecorderStateType.Error:
        logger.info('MEDIA_RECORDER.DESTROY_IGNORED', state);
        return;
      default:
        break;
    }

    this.setState({
      type: MediaRecorderStateType.Destroyed,
    });

    if (state.type === MediaRecorderStateType.Recording) {
      state.recorder.destroy();
    }
  }
}
