import { RecordWaveform } from '../components/RecordWaveform/RecordWaveform';
import { TICKS_PER_SIZE } from '../components/RecordWaveform/RecordWaveform.constants';
import { useAudioFrequencyData } from '../hooks/audio-analyzer';

export interface RecordWaveformContainerProps {
  stream: MediaStream;
  size: 'small' | 'medium' | 'large';
  color?: 'blend-50' | 'blend-70';
}

export function RecordWaveformContainer({ stream, size, color }: RecordWaveformContainerProps) {
  const samples = useAudioFrequencyData(stream, TICKS_PER_SIZE[size]);
  return <RecordWaveform size={size} samples={samples} color={color} />;
}
