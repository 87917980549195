import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMicrophone, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { ACL_VIEW_ROLES } from '@remento/types/acl';
import { EntityType } from '@remento/types/entity';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { useLastInterviewSessionsByPromptId } from '@/modules/conversation-recorder/interview';
import { RecordingPromptContainer } from '@/modules/recording/containers/RecordingPrompt.container';
import { RecordingStorytellerSelectionDialogContainer } from '@/modules/recording/containers/RecordingStorytellerSelectionDialog.container';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getProjectRecordPath, getRecordingIntroPath, getRecordingPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles, usePrimaryAclGroup } from '@/services/api/acl';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, usePromptQuery } from '@/services/api/project';

import { Content, RecordingTypesButtons, RecordingTypesContent } from './RecordingTypeSelectionPage.styles';

interface RecordingTypeSelectionPageProps {
  projectId: string;
  promptId: string;
}

function RecordingTypeSelectionPage({ projectId, promptId }: RecordingTypeSelectionPageProps) {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  // Services
  const { storytellingAnalyticsService } = useServices();
  const { lastSession } = useLastInterviewSessionsByPromptId(params.promptId ?? null);
  const [recordingStorytellerSelectorDialogOpen, setRecordingStorytellerSelectorDialogOpen] = useState(false);

  // Entities
  const user = useUser();
  const promptQuery = usePromptQuery(promptId);
  const projectQuery = useProjectQuery(projectId);
  const storytellerQuery = usePersonQuery(projectQuery.data?.subjectPersonIds[0]);
  const projectAcl = usePrimaryAclGroup(projectQuery.data?.acl, EntityType.PROJECT);
  const userProjectRoles = useCurrentUserAclRoles(projectAcl ? [projectAcl.id] : null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  // Callbacks
  const goToRecord = useCallback(
    (type: 'audio' | 'video') => {
      navigate(getRecordingPath({ projectId, promptId, type, customParams: searchParams }));
      storytellingAnalyticsService.onStorytellingInputSelected(
        user?.personId === promptQuery.data?.requesterPersonId ? 'sender' : 'recipient',
        type,
        referrer,
      );
    },
    [
      navigate,
      promptId,
      promptQuery.data?.requesterPersonId,
      searchParams,
      projectId,
      storytellingAnalyticsService,
      user?.personId,
      referrer,
    ],
  );

  const handleRecordingStorytellerSelectionDialogConfirm = useCallback(
    (recorderPersonId: string) => {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.set('recorder-person-id', recorderPersonId);

      setSearchParams(newSearchParams);
      setRecordingStorytellerSelectorDialogOpen(false);
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    const currentRecorderPersonId = searchParams.get('recorder-person-id');
    if (currentRecorderPersonId != null) {
      return;
    }

    if (user == null) {
      return;
    }

    if (!canViewProject) {
      return;
    }

    const isStoryteller = storytellerQuery.data == null || storytellerQuery.data.refIds.includes(user.personId);
    if (isStoryteller) {
      return;
    }

    setRecordingStorytellerSelectorDialogOpen(true);
  }, [searchParams, user, storytellerQuery, canViewProject]);

  const handleBack = useCallback(() => {
    if (searchParams.get('referrer') === 'project-record') {
      navigate(getProjectRecordPath(projectId, undefined, searchParams));
    } else {
      navigate(getRecordingIntroPath(projectId, promptId, searchParams));
    }
  }, [navigate, projectId, promptId, searchParams]);

  useEffect(() => {
    if (lastSession == null) {
      return;
    }

    navigate(getRecordingPath({ projectId, promptId, type: lastSession.recordingType, customParams: searchParams }));
  }, [lastSession, navigate, projectId, promptId, searchParams]);

  return (
    <RecordingLayout.Root
      Header={
        <RecordingLayout.Header>
          <RMIconButton
            icon={faChevronLeft}
            size="xl"
            tooltip={null}
            backgroundColor="transparent"
            onClick={handleBack}
          />
        </RecordingLayout.Header>
      }
    >
      <Content>
        <RecordingPromptContainer promptId={promptId} />
        <RecordingTypesContent>
          <RMText type="sans" size="s" color="on-surface-primary">
            Choose your recording mode
          </RMText>
          <RecordingTypesButtons
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { delay: 0.2, duration: 0.2, ease: 'easeOut' },
            }}
          >
            <RMButton
              leftIcon={faMicrophone}
              background="neutral"
              size="massive"
              fullWidth
              onClick={() => goToRecord('audio')}
            >
              Audio
            </RMButton>

            <RMButton
              leftIcon={faVideo}
              background="primary"
              size="massive"
              fullWidth
              onClick={() => goToRecord('video')}
            >
              Video
            </RMButton>
          </RecordingTypesButtons>
        </RecordingTypesContent>

        <RecordingStorytellerSelectionDialogContainer
          projectId={projectId}
          open={recordingStorytellerSelectorDialogOpen}
          onContinue={handleRecordingStorytellerSelectionDialogConfirm}
        />
      </Content>
    </RecordingLayout.Root>
  );
}

export function RecordingTypeSelectionPageContainer() {
  const params = useParams();

  return (
    <RementoPage type="record">
      <RecordingTypeSelectionPage projectId={params.projectId ?? ''} promptId={params.promptId ?? ''} />
    </RementoPage>
  );
}
