import { useEffect, useState } from 'react';

export function useFileDataUrl(file: File | null): string | null {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    if (file == null) {
      setUrl(null);
      return;
    }

    const fileReader = new FileReader();
    const onLoad = () => {
      setUrl(fileReader.result as string);
    };

    fileReader.addEventListener('load', onLoad);
    fileReader.readAsDataURL(file);

    return () => {
      fileReader.removeEventListener('load', onLoad);
      fileReader.abort();
    };
  }, [file]);

  return url;
}
