import { styled } from '@linaria/react';

import { RMImage } from '@/components/RMImage/RMImage';
import { RMText } from '@/components/RMText/RMText';

export const StyledProjectRecordCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 480px;
  max-height: 580px;
  height: 100%;
  background-color: var(--surface-brightest);
  border-radius: var(--radius-smooth);
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-2);
`;

export const HeaderText = styled(RMText)`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
  text-transform: uppercase;
  z-index: 1;
`;

export const ContentText = styled(RMText)`
  z-index: 1;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: var(--spacing-md);
  padding: var(--spacing-xl) var(--spacing-3xl);

  body[data-mobile='true'] & {
    padding: var(--spacing-xl);
  }
`;

export const BackgroundImage = styled(RMImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 0;
  border-top-left-radius: var(--radius-smooth);
  border-top-right-radius: var(--radius-smooth);
  object-fit: cover;

  ::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: var(--spacing-md);
  border-top: 1px solid var(--border-weak);
`;
