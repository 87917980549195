import { useCallback, useMemo, useState } from 'react';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { PromptTemplateTagHero } from '@/modules/project/components/PromptTemplateTagHero/PromptTemplateTagHero';
import { PromptTemplateTagListContainer } from '@/modules/project/containers/PromptTemplateTagList.container';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';
import { PromptTemplateTag } from '@/services/cms/prompt-template-tags/prompt-template-tags.types';

import { PromptTemplateListContainer } from './PromptTemplateList.container';

interface BrowseTagsDialogState {
  type: 'browse-tags';
}

interface BrowsePromptsDIalogState {
  type: 'browse-prompts';
  tag: PromptTemplateTag;
}

type DialogState = BrowseTagsDialogState | BrowsePromptsDIalogState;

export interface PromptTemplatePickerDialogContainerProps {
  onSelect: (template: PromptTemplate, tag: PromptTemplateTag) => void;
  onClose: () => void;
}

export function PromptTemplatePickerDialogContainer({ onSelect, onClose }: PromptTemplatePickerDialogContainerProps) {
  const [dialogState, setDialogState] = useState<DialogState>({ type: 'browse-tags' });
  const [animationDirection, setAnimationDirection] = useState<'ltr' | 'rtl' | undefined>();

  const title = useMemo(() => {
    switch (dialogState.type) {
      case 'browse-tags':
        return 'Browse categories';
      case 'browse-prompts':
        return 'Choose a question';
    }
  }, [dialogState.type]);

  const handleSelectTag = useCallback((tag: PromptTemplateTag) => {
    setAnimationDirection('rtl');
    setTimeout(() => {
      setDialogState({ type: 'browse-prompts', tag });
    });
  }, []);

  const handleBack = useCallback(() => {
    setAnimationDirection('ltr');
    setTimeout(() => {
      setDialogState({ type: 'browse-tags' });
    });
  }, []);

  return (
    <RMDialog.Root open variant="full-screen" onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title={title}
          centerTitle
          leftAdornment={
            dialogState.type === 'browse-prompts' ? (
              <RMIconButton
                icon={faChevronLeft}
                tooltip={null}
                backgroundColor="transparent"
                stateColor="darken-neutral"
                onClick={handleBack}
              />
            ) : null
          }
          rightAdornment={<RMCloseButton onClick={onClose} />}
        />
        <RMDialog.Transition key={dialogState.type} animation={animationDirection} overflow="auto">
          {dialogState.type === 'browse-tags' && (
            <RMDialog.Body>
              <PromptTemplateTagListContainer onSelectTag={handleSelectTag} />
            </RMDialog.Body>
          )}
          {dialogState.type === 'browse-prompts' && (
            <RMDialog.Body>
              <PromptTemplateTagHero tag={dialogState.tag} />
              <RMSpacer spacing="2xl" direction="column" />
              <PromptTemplateListContainer tag={dialogState.tag.value} onSelect={(t) => onSelect(t, dialogState.tag)} />
            </RMDialog.Body>
          )}
        </RMDialog.Transition>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
