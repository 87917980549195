import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import logomarkSrc from '@/@assets/logomark-white.svg';

import { CheckIcon, Logomark, StyledCircularProgress } from './RMCircularProgress.styles';

export interface RMCircularProgressProps {
  size: number;
  progress: number;
}

export function RMCircularProgress({ size, progress }: RMCircularProgressProps) {
  const strokeWidth = 10;
  const radius = (size - strokeWidth) / 2;
  const circumference = Math.PI * radius * 2;
  const strokeDashoffset = circumference - progress * circumference;

  return (
    <StyledCircularProgress>
      <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#fff"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          style={{ transform: 'rotate(-90deg)', transformOrigin: '50% 50%' }}
        />
      </svg>
      {progress !== 1 && <Logomark src={logomarkSrc} alt="Remento logo" />}
      {progress === 1 && <CheckIcon icon={faCheck} color="#fff" size="3x" />}
    </StyledCircularProgress>
  );
}
