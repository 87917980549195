import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Banner = styled(motion.div)`
  text-align: center;
  width: 100%;

  &[data-bgcolor='danger'] {
    background: var(--danger);
  }

  &[data-bgcolor='banner-info'] {
    background: var(--banner-info);
  }

  body[data-mobile='false'] & {
    padding: var(--spacing-md);
  }

  body[data-mobile='true'] & {
    padding: var(--spacing-sm);
  }
`;
