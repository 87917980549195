import { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { useInterviewManager } from '@/modules/conversation-recorder/interview';
import { useInterviewManagerTicker } from '@/modules/conversation-recorder/interview/interview-manager-ticker';

import { RecordingBanner } from '../components/RecordingBanner/RecordingBanner';

interface RecordingBannerContainerProps {
  hideBanner: boolean;
  onRecordTimeout: () => void;
}

export function RecordingBannerContainer({ hideBanner, onRecordTimeout }: RecordingBannerContainerProps) {
  const interviewManager = useInterviewManager();
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState(0);

  useInterviewManagerTicker(() => {
    try {
      const remainingTime = interviewManager.getRemainingRecordingDuration();
      if (remainingTime < 2 * 60) {
        setShowBanner(true);
      }

      if (remainingTime <= 0) {
        onRecordTimeout();
      } else {
        setRemainingTime(remainingTime);
      }
    } catch (error) {
      // interview manager died
    }
  });

  const formatRemainingTime = useMemo(() => {
    const duration = dayjs.duration(remainingTime, 'seconds');
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    if (minutes > 0) {
      return `${minutes} minutes and ${seconds} seconds`;
    } else {
      return `${seconds} seconds`;
    }
  }, [remainingTime]);

  useEffect(() => {
    const unsubscribe = interviewManager.subscribe(() => {
      setShowBanner(false);
    });

    return () => {
      unsubscribe();
    };
  }, [interviewManager]);

  if (!showBanner || hideBanner || remainingTime < 0) {
    return null;
  }

  return (
    <RecordingBanner
      message={`You have ${formatRemainingTime} left, time to wrap it up 😊`}
      textColor={'on-surface-primary'}
      bgColor={'banner-info'}
    />
  );
}
