import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';

import { InterviewManager } from './interview-manager';
import { useInterviewManager } from './interview-manager.provider';
import { InterviewManagerStateType } from './interview-manager.types';

export type TickerCallback = () => void;

export class InterviewManagerTicker {
  private callbacks = new Set<TickerCallback>();
  private unsubscribe: (() => void) | null = null;
  private interval: ReturnType<typeof setInterval> | null = null;

  constructor(private interviewManager: InterviewManager) {}

  start() {
    if (this.unsubscribe != null) {
      // We're already started
      return;
    }
    this.unsubscribe = this.interviewManager.subscribe(
      (state) => {
        // Start the recording
        if (state.type === InterviewManagerStateType.Recording) {
          if (this.interval != null) {
            clearInterval(this.interval);
          }
          this.callbacks.forEach((c) => c());
          this.interval = setInterval(() => this.callbacks.forEach((c) => c()), 500);
        }

        // Pause the recording
        if (state.type === InterviewManagerStateType.Paused) {
          if (this.interval != null) {
            clearInterval(this.interval);
          }
        }
      },
      { immediate: true },
    );
  }

  stop() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
    this.unsubscribe?.();
    this.unsubscribe = null;
  }

  registerCallback(callback: TickerCallback): () => void {
    this.callbacks.add(callback);
    return () => this.callbacks.delete(callback);
  }
}

export const InterviewManagerTickerContext = createContext<InterviewManagerTicker | null>(null);

export function InterviewManagerTickerProvider({ children }: PropsWithChildren) {
  const interviewManager = useInterviewManager();
  const ticker = useMemo(() => new InterviewManagerTicker(interviewManager), [interviewManager]);
  useEffect(() => {
    ticker.start();
    return () => ticker.stop();
  }, [ticker]);

  return <InterviewManagerTickerContext.Provider value={ticker}>{children}</InterviewManagerTickerContext.Provider>;
}

export function useInterviewManagerTicker(callback: TickerCallback) {
  const ticker = useContext(InterviewManagerTickerContext);
  useEffect(() => ticker?.registerCallback(callback), [callback, ticker]);
}
