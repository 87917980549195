import { useMemo } from 'react';

import { useServices } from '@/Services';

export function useUrlWithTokens(urlString: string): string {
  const { authorizationService } = useServices();

  return useMemo(() => {
    const url = new URL(urlString);
    const tokens = authorizationService.getPermissionTokens();
    tokens.forEach((token) => {
      url.searchParams.append('token', token);
    });
    return url.toString();
  }, [authorizationService, urlString]);
}

export function useCurrentUrlWithTokens(): string {
  return useUrlWithTokens(window.location.href);
}
