import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { LottieAnimation } from '@/components/LottieAnimation';

import { RMText } from '../../../../components/RMText/RMText';

export const ConfettiPage = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const LottieContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const ConfettiTextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SharedPage = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--surface-always-dark);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--spacing-4xl) var(--spacing-md) var(--spacing-xl) var(--spacing-md);
  padding-bottom: 0;
  overflow: hidden;

  body[data-mobile='true'] & {
    padding: var(--spacing-md) var(--spacing-md) 0 var(--spacing-md);
  }
`;

export const Logo = styled.img`
  width: 56px;
`;

export const SharedPageBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TimesContainer = styled.div`
  display: flex;
  gap: var(--spacing-4xl);
`;

export const TimePartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TimeText = styled.span`
  text-align: center;
  font-family: FKGroteskNeue;
  font-size: 50px;
  font-weight: 700;
  line-height: 140%;
  color: var(--inverse-on-surface-primary);
`;

export const SharedPageFooter = styled(motion.div)`
  margin-top: auto;
  width: 100%;
  max-width: 33.25rem;
  padding-bottom: var(--spacing-xl);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
`;

export const CenteredText = styled(RMText)`
  max-width: 20rem;
  text-align: center;
`;

export const ProjectMembersWrapper = styled(motion.div)`
  width: 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  position: absolute;
  bottom: var(--spacing-4xl);

  body[data-mobile='true'] & {
    bottom: var(--spacing-2xl);
  }
`;

export const LottieSpinner = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
`;
