import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '../RMText/RMText';

import { ImageIcon, StyledButtonListItem } from './RMButtonListItem.styles';

export interface RMButtonListItemProps {
  label: string;
  icon?: string | IconDefinition;
  size?: 's' | 'm';
  color?: 'primary' | 'on-surface-primary';
  bold?: boolean;
  onClick?: () => void;
}

export function RMButtonListItem({
  label,
  icon,
  size = 's',
  color = 'primary',
  bold = true,
  onClick,
}: RMButtonListItemProps) {
  return (
    <StyledButtonListItem onClick={onClick}>
      <RMText type="sans" size={size} color={color} bold={bold}>
        {label}
      </RMText>
      {typeof icon === 'string' && <ImageIcon src={icon} />}
      {typeof icon === 'object' && <FontAwesomeIcon icon={icon} />}
    </StyledButtonListItem>
  );
}
