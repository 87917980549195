import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { RMButtonList } from '@/components/RMButtonList';
import { usePromptTemplatesQuery } from '@/services/cms/prompt-template/prompt-template.service.hook';
import { PromptTemplate } from '@/services/cms/prompt-template/prompt-template.types';

export interface PromptTemplateListContainerProps {
  tag: string;
  onSelect: (template: PromptTemplate) => void;
}

export function PromptTemplateListContainer({ tag, onSelect }: PromptTemplateListContainerProps) {
  const promptTemplatesQuery = usePromptTemplatesQuery(tag);

  return (
    <RMButtonList.Root>
      {promptTemplatesQuery.data?.map((template) => (
        <RMButtonList.Item
          key={template.id}
          label={template.questions[0].text}
          icon={faChevronRight}
          bold={false}
          size="m"
          color="on-surface-primary"
          onClick={() => onSelect(template)}
        />
      ))}
    </RMButtonList.Root>
  );
}
