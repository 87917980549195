import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

import { RMButton } from '@/components/RMButton/RMButton';

export const PageContent = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-4xl);
  height: calc(100% - 6rem);

  body[data-mobile='true'] & {
    flex-direction: column;
    height: 100%;
    gap: var(--spacing-2xl);
  }
`;

export const LeftCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xl);
  padding: var(--spacing-3xl) var(--spacing-2xl);
  min-width: 196px;
  background-color: var(--surface-dim);
  border: 1px solid var(--border-weak);
  border-radius: var(--radius-smooth);

  body[data-mobile='true'] & {
    width: 100%;
  }
`;

export const Logomark = styled.img`
  height: 44px;
`;

export const RightCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);

  body[data-mobile='true'] & {
    flex: 1;
  }
`;

export const Button = styled(RMButton)`
  body[data-mobile='true'] & {
    width: 100%;
    margin-top: auto;
  }
`;

export const WarningIcon = styled(FontAwesomeIcon)`
  color: var(--primary);
  height: 48px;
`;
