import { useEffect, useMemo, useState } from 'react';

import { getQueryParam } from '@/hooks/useQueryParam';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, usePromptQuery } from '@/services/api/project';

export function useRecorderName(projectId: string): string | null {
  const user = useUser();

  const [personId, setPersonId] = useState<string | null>(null);

  const projectQuery = useProjectQuery(projectId);
  const personQuery = usePersonQuery(personId);

  useEffect(() => {
    if (projectQuery.data == null) {
      return;
    }

    const recorderPersonId = getQueryParam(`recorder-person-id`);
    if (recorderPersonId != null) {
      if (projectQuery.data.notifications.recipientPersonIds.includes(recorderPersonId)) {
        setPersonId(recorderPersonId);
        return;
      }
    }

    setPersonId(user?.personId ?? null);
  }, [projectQuery.data, user?.personId]);

  return personQuery.data?.name?.first ?? null;
}

export function useRequesterName(promptId: string, fallbackCurrentUser?: string | null): string | null {
  const user = useUser();
  const recorderPersonId = getQueryParam(`recorder-person-id`) ?? user?.personId ?? null;

  const promptQuery = usePromptQuery(promptId);
  const requesterPersonQuery = usePersonQuery(promptQuery.data?.requesterPersonId);

  return useMemo(() => {
    if (requesterPersonQuery.data == null || recorderPersonId == null) {
      return null;
    }

    if (fallbackCurrentUser != null && requesterPersonQuery.data.refIds.includes(recorderPersonId)) {
      return fallbackCurrentUser;
    }

    return requesterPersonQuery.data.name?.first ?? null;
  }, [fallbackCurrentUser, recorderPersonId, requesterPersonQuery.data]);
}
