import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Hls from 'hls.js';

import { InterviewManagerRecording, useBlobsUrl } from '@/modules/conversation-recorder/interview';

import { RecordingPlaybackContext, RecordingPlaybackContextData } from './RecordingPlayback.context';

type RecordingPlaybackRootProps = PropsWithChildren<{
  recordings: InterviewManagerRecording[];
  stream: boolean;
  initialTime?: number;
}>;

export function RecordingPlaybackRoot({ recordings, stream, initialTime, children }: RecordingPlaybackRootProps) {
  const videoRef = useRef<HTMLVideoElement>(null);

  const recordingsUrl = useBlobsUrl(recordings);
  const [currentRecordingIndex, setCurrentRecordingIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const videoSrc = useMemo(() => {
    return recordingsUrl ? recordingsUrl[currentRecordingIndex] : null;
  }, [currentRecordingIndex, recordingsUrl]);

  const handleEnded = useCallback(() => {
    if (currentRecordingIndex === recordings.length - 1) {
      setCurrentRecordingIndex(0);
      return;
    }

    setCurrentRecordingIndex((index) => index + 1);
    if (videoRef.current !== null) {
      videoRef.current.play();
    }
  }, [currentRecordingIndex, recordings.length]);

  useEffect(() => {
    if (videoRef.current === null && recordings.length > 0) {
      console.warn('videoRef is null');
      return;
    }

    if (videoRef.current === null) {
      return;
    }

    if (!videoSrc) {
      return;
    }

    if (stream && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(videoRef.current);

      return () => {
        hls.destroy();
      };
    } else {
      videoRef.current.src = videoSrc;
      if (currentRecordingIndex > 0) {
        videoRef.current.play();
      }
    }

    if (initialTime !== undefined) {
      videoRef.current.currentTime = currentRecordingIndex === 0 ? initialTime : 0;
    }

    return () => {
      if (!videoRef.current?.paused && currentRecordingIndex > 0) {
        videoRef.current?.pause();
      }
    };
  }, [videoSrc, videoRef, stream, initialTime, currentRecordingIndex, recordings.length]);

  const value = useMemo<RecordingPlaybackContextData>(
    () => ({
      videoRef,
      currentRecordingIndex: currentRecordingIndex,
      recordingsUrl: recordingsUrl,
      durations: recordings.map(({ duration }) => duration),
      totalDuration: recordings.reduce((total, recording) => total + recording.duration, 0),
      currentTime: currentTime,
      onEnded: handleEnded,
      setCurrentTime: (currentTime: number) => setCurrentTime(currentTime),
      setCurrentRecordingIndex: (index: number) => setCurrentRecordingIndex(index),
    }),
    [currentRecordingIndex, currentTime, handleEnded, recordings, recordingsUrl],
  );

  return <RecordingPlaybackContext.Provider value={value}>{children}</RecordingPlaybackContext.Provider>;
}
