import { RecordingTime } from './RecordingDuration.styles';

interface RecordingDurationProps {
  currentDuration: string | null;
  maxDuration: string | null;
}

export function RecordingDuration({ currentDuration, maxDuration }: RecordingDurationProps) {
  return (
    <RecordingTime>
      {currentDuration} / {maxDuration}
    </RecordingTime>
  );
}
