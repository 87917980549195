import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMDialog } from '@/components/RMDialog';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  MINIMUM_RECORDING_DURATION,
  useInterviewManager,
  useObserveRecordingInterruptedError,
  usePromptRecordings,
} from '@/modules/conversation-recorder/interview';
import { InterviewManagerTickerProvider } from '@/modules/conversation-recorder/interview/interview-manager-ticker';
import { InterviewSession } from '@/modules/conversation-recorder/interview-session';
import { useServices } from '@/Services';
import { captureException } from '@/utils/captureException';

import { RecordingStep } from '../components/RecordingStep/RecordingStep';
import {
  RecordingWidget,
  RecordingWidgetAction,
  RecordingWidgetState,
} from '../components/RecordingWidget/RecordingWidget';

import { RecordingBannerContainer } from './RecordingBannerContainer.container';
import { RecordingIndicatorContainer } from './RecordingIndicator.container';
import { RecordingPromptContainer } from './RecordingPrompt.container';

export interface RecordingStepContainerProps {
  promptId: string;
  type: 'audio' | 'video';
  persona: 'recipient' | 'sender';
  mediaStream: MediaStream;
  RecordingDeviceButton: ReactNode;
  resumingSession: InterviewSession | null;
  onFinish: (duration: number) => void;
  onBack: () => void;
}

export function RecordingStepContainer({
  promptId,
  type,
  persona,
  mediaStream,
  RecordingDeviceButton,
  resumingSession,
  onFinish,
  onBack,
}: RecordingStepContainerProps) {
  const isMobile = useIsMobileViewport();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  // Services
  const { storytellingAnalyticsService } = useServices();

  // Recording
  const interviewManager = useInterviewManager();
  const [widgetState, setWidgetState] = useState<RecordingWidgetState>(() => ({ type: 'ready', isFirstTake: true }));
  const latestPromptRecordings = usePromptRecordings();
  const [tooShortDialogOpen, setTooShortDialogOpen] = useState(false);

  const [isRetakeModalOpen, setRetakeModalOpen] = useState(false);

  // Callbacks
  const handleStartRecording = useCallback(async () => {
    await interviewManager.startRecording();
    storytellingAnalyticsService.onStorytellingRecordingStarted(persona, type, referrer);
  }, [interviewManager, persona, storytellingAnalyticsService, type, referrer]);

  const handleStopRecording = useCallback(async () => {
    try {
      const { duration } = await interviewManager.pauseRecording();
      storytellingAnalyticsService.onStorytellingRecordingEnded(persona, type, duration, referrer);
      return { duration };
    } catch (error) {
      captureException(error, true);
      return { duration: 0 };
    }
  }, [interviewManager, persona, storytellingAnalyticsService, type, referrer]);

  const handleRewindPrompt = useCallback(async () => {
    try {
      await interviewManager.retakePrompt();
      storytellingAnalyticsService.onStorytellingRestarted(persona, type, referrer);
    } catch (error) {
      captureException(error, true);
    }
  }, [interviewManager, persona, storytellingAnalyticsService, type, referrer]);

  const handleEndRecording = useCallback(async () => {
    try {
      const { duration } = await interviewManager.endRecording();
      onFinish(duration);
      storytellingAnalyticsService.onStorytellingRecordingFinished(persona, type, duration, referrer);
    } catch (error) {
      captureException(error, true);
    }
  }, [interviewManager, onFinish, persona, storytellingAnalyticsService, type, referrer]);

  const handleRetakePrompt = useCallback(async () => {
    setTooShortDialogOpen(false);

    try {
      await handleRewindPrompt();
      setRetakeModalOpen(false);
      setWidgetState({ type: 'ready', isFirstTake: false });
    } catch (error) {
      captureException(error, true);
      toast('Failed to rewind', 'dialog-panel-toast', 'error');
    }
  }, [handleRewindPrompt]);

  const handleRetakeCancel = useCallback(() => {
    setWidgetState({ type: 'paused', isRecordingValid: true });
    setRetakeModalOpen(false);
  }, []);

  const handleWidgetAction = useCallback(
    async (action: RecordingWidgetAction) => {
      switch (action) {
        case 'start-recording': {
          await handleStartRecording();
          setWidgetState({ type: 'recording' });
          break;
        }
        case 'stop-recording': {
          const { duration } = await handleStopRecording();
          setWidgetState({ type: 'paused', isRecordingValid: duration >= MINIMUM_RECORDING_DURATION });

          if (duration < MINIMUM_RECORDING_DURATION) {
            setTooShortDialogOpen(true);
          }
          break;
        }
        case 'rewind-prompt': {
          // too short: don't show confirm dialog
          if (widgetState.type === 'paused' && widgetState.isRecordingValid === false) {
            handleRetakePrompt();
          } else {
            setRetakeModalOpen(true);
          }
          break;
        }
        case 'confirm-recording': {
          setWidgetState({ type: 'confirm' });
          break;
        }
        case 'close-confirm': {
          setWidgetState({ type: 'paused', isRecordingValid: true });
          break;
        }
        case 'end-recording': {
          await handleEndRecording();
          setWidgetState({ type: 'finished' });
          break;
        }
      }
    },
    [handleEndRecording, handleRetakePrompt, handleStartRecording, handleStopRecording, widgetState],
  );

  // Show the interrupted modal if an error happens
  const [recordingInterrupted, setRecordingInterrupted] = useState(false);
  useObserveRecordingInterruptedError(
    useCallback(
      (error) => {
        setRecordingInterrupted(true);
        setWidgetState({ type: 'ready', isFirstTake: false });

        switch (error.reason) {
          case 'no-data-received':
            storytellingAnalyticsService.onStorytellingRecordingNoDataError(persona, type, referrer);
            break;
          case 'recorder-interrupted':
            storytellingAnalyticsService.onStorytellingRecordingInterruptedError(persona, type, referrer);
            break;
          case 'queue-disconnected':
            storytellingAnalyticsService.onStorytellingRecordingQueueDisconnectedError(persona, type, referrer);
            break;
        }
      },
      [persona, storytellingAnalyticsService, type, referrer],
    ),
  );

  // Resuming session
  useEffect(() => {
    if (resumingSession == null) {
      return;
    }

    setWidgetState({ type: 'paused', isRecordingValid: true });
  }, [interviewManager, resumingSession]);

  // Check if tab is active, if not, pause the recording
  const onVisibilityChange = useCallback(() => {
    if (widgetState.type !== 'recording') {
      return;
    }

    if (document.visibilityState === 'hidden') {
      handleWidgetAction('stop-recording');
    }
  }, [handleWidgetAction, widgetState.type]);

  // Check if tab is active, if not, pause the recording
  const onCloseTab = useCallback(
    async (event: BeforeUnloadEvent) => {
      if (widgetState.type !== 'recording') {
        return;
      }

      const confirmationMessage = 'This action will erase your current recording. Are you sure you want to proceed?';

      handleWidgetAction('stop-recording');
      event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    },
    [handleWidgetAction, widgetState.type],
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);
    window.addEventListener('beforeunload', onCloseTab);

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
      window.removeEventListener('beforeunload', onCloseTab);
    };
  }, [onCloseTab, onVisibilityChange]);

  return (
    <InterviewManagerTickerProvider>
      <RecordingStep
        Banner={
          <RecordingBannerContainer
            onRecordTimeout={() => handleWidgetAction('stop-recording')}
            hideBanner={widgetState.type === 'ready'}
          />
        }
        RecordingIndicator={<RecordingIndicatorContainer widgetState={widgetState.type} audioStream={mediaStream} />}
        RecordingPrompt={
          <RecordingPromptContainer promptId={promptId} fullscreenEnabled={widgetState.type !== 'recording'} />
        }
        RecordingWidget={
          <RecordingWidget
            state={widgetState}
            mediaStream={mediaStream}
            recordings={latestPromptRecordings}
            recordingType={type}
            disabled={isRetakeModalOpen || tooShortDialogOpen}
            onAction={handleWidgetAction}
          />
        }
        RecordingDeviceButton={RecordingDeviceButton}
        onBack={widgetState.type === 'ready' && widgetState.isFirstTake ? onBack : null}
      />
      <RMConfirmationModal
        open={tooShortDialogOpen}
        title="Your recording is a bit too short."
        message={`Recordings must be at least ${MINIMUM_RECORDING_DURATION} seconds long.`}
        confirmLabel="Start over"
        onConfirm={handleRetakePrompt}
        cancelLabel={null}
        btnFullWidth={isMobile}
        onClose={null}
      />
      <RMConfirmationModal
        open={isRetakeModalOpen}
        title="Heads up!"
        message="This action will erase your current recording. Are you sure you want to proceed?"
        type="danger"
        confirmLabel="Retake"
        onConfirm={handleRetakePrompt}
        onCancel={handleRetakeCancel}
      />

      <RMDialog.Root open={recordingInterrupted}>
        <RMDialog.Content>
          <RMDialog.Header title={`${type === 'video' ? 'Video' : 'Audio'} connection lost`} />
          <RMDialog.Body>
            <RMText type="sans" size="s" color="on-surface-primary">
              Unfortunately, your {type === 'video' ? 'video' : 'audio'} connection was lost due to a technical error.
              Please try recording again. If the issue persists, please contact{' '}
              <a href="mailto:support@remento.co">
                <RMText type="sans" size="s" color="primary" underline>
                  support@remento.co
                </RMText>
              </a>
              .
            </RMText>
          </RMDialog.Body>
          <RMDialog.Footer>
            <RMButton background="primary" onClick={() => window.location.reload()} fullWidth>
              Restart recording
            </RMButton>
          </RMDialog.Footer>
        </RMDialog.Content>
      </RMDialog.Root>
    </InterviewManagerTickerProvider>
  );
}
