import { createContext, RefObject, useContext } from 'react';

export interface RecordingPlaybackContextData {
  videoRef: RefObject<HTMLVideoElement>;
  currentRecordingIndex: number;
  recordingsUrl: string[] | null;
  durations: number[];
  totalDuration: number;
  currentTime: number;
  setCurrentTime: (currentTime: number) => void;
  setCurrentRecordingIndex: (index: number) => void;
  onEnded: () => void;
}

export const RecordingPlaybackContext = createContext<RecordingPlaybackContextData | null>(null);

export function useRecordingPlayback() {
  const context = useContext(RecordingPlaybackContext);
  if (!context) {
    throw new Error('useRecordingContext must be used within a RecordingContextProvider');
  }

  return context;
}
