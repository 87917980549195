import { styled } from '@linaria/react';

import { LottieAnimation } from '@/components/LottieAnimation';
import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  height: 100%;
  max-width: 33.25rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-2xl);
`;

export const LoadingContent = styled.div`
  width: 17rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xl);
  transform: translateY(calc(var(--spacing-2xl) * -1));
`;

export const CameraPlaceholder = styled.div`
  overflow: hidden;
  max-width: 31.25rem;
  max-height: 100%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);

  body[data-mobile='true'] & {
    height: 100%;
    width: 100%;
  }
`;

export const CameraPlaceholderText = styled(RMText)`
  white-space: pre-line;
`;

export const AllowInstructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);

  &[data-safari='true'],
  body[data-mobile='true'] & {
    position: absolute;
    bottom: 0;
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  width: 100%;

  padding: var(--spacing-md);
  body[data-mobile='true'] & {
    padding: 0;
  }
`;

export const SpinnerAnimation = styled(LottieAnimation)`
  position: relative;
  width: 6rem;
  height: 6rem;
  flex: 0 0 6rem;
`;

export const ArrowAnimation = styled(LottieAnimation)`
  position: relative;
  width: 2rem;
  height: 2rem;
  flex: 0 0 2rem;
  transform: rotate(180deg);
`;
