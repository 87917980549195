import { useCallback } from 'react';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faGear } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';

import { RecordWaveformContainer } from '../../containers/RecordWaveform.container';
import { RecordingLayout } from '../../layouts/RecordingLayout';

import {
  BackButtonWrapper,
  ButtonWrapper,
  CenteredText,
  Content,
  Footer,
  Video,
  VideoContainer,
  WaveformContainer,
} from './RecordReady.styles';

export interface RecordReadyProps {
  type: 'audio' | 'video';
  name: string | null;
  mediaStream: MediaStream;
  onContinue: () => void;
  onOpenDevicesModal: () => void;
  onBack: () => void;
}

export function RecordReady({ type, name, mediaStream, onContinue, onOpenDevicesModal, onBack }: RecordReadyProps) {
  const videoRef = useCallback(
    (videoElement: HTMLVideoElement | null) => {
      if (!videoElement) {
        return;
      }

      videoElement.srcObject = mediaStream;
      // We're doing this instead of using the `autoplay` prop cause iOS (Webkit) displays a play/pause button
      // on top of the video when in Low Power Mode. Check: https://stackoverflow.com/a/69709669
      videoElement.play();
    },
    [mediaStream],
  );

  return (
    <RecordingLayout.Root
      Header={
        <RecordingLayout.Header>
          <BackButtonWrapper>
            <RMIconButton
              icon={faChevronLeft}
              size="xl"
              tooltip={null}
              backgroundColor="transparent"
              onClick={onBack}
            />
          </BackButtonWrapper>
          {type === 'video' && <RecordWaveformContainer size="small" stream={mediaStream} />}
          <ButtonWrapper>
            <RMIconButton
              icon={faGear}
              size="xl"
              tooltip={{ label: 'Settings', position: 'bottom' }}
              color="neutral"
              backgroundColor="transparent"
              onClick={onOpenDevicesModal}
            />
          </ButtonWrapper>
        </RecordingLayout.Header>
      }
    >
      <Content>
        <CenteredText type="serif" size="l" color="on-surface-primary">
          {type === 'audio' && '🎙 Mic check 1, 2, 3 '}
          {type === 'video' && name != null && `Looking good, ${name}! 😍`}
          {type === 'video' && name == null && `Looking good! 😍`}
        </CenteredText>
        {type === 'audio' && (
          <WaveformContainer>
            <RecordWaveformContainer size="large" stream={mediaStream} />
            <CenteredText type="sans" size="s" color="on-surface-primary">
              Line not moving when you speak? Tap the gear in the top right corner to troubleshoot.
            </CenteredText>
          </WaveformContainer>
        )}
        {type === 'video' && (
          <VideoContainer>
            <Video muted playsInline ref={videoRef} />
          </VideoContainer>
        )}
        <Footer>
          <RMText type="sans" size="s" color="on-surface-primary" align="center" bold>
            Ready to share your memory?
          </RMText>

          <RMButton size="massive" background="primary" fullWidth autoLoading onClick={onContinue}>
            Continue
          </RMButton>
        </Footer>
      </Content>
    </RecordingLayout.Root>
  );
}
