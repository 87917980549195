export interface Subscription {
  remove: () => void;
}

export type Listener<E, T> = (data: T, event: E) => void;

export class EventEmitter<EventMap extends Record<string, unknown> = Record<string, unknown>> {
  private listeners = new Map<keyof EventMap, Set<Listener<unknown, unknown>>>();

  addListener<E extends keyof EventMap>(event: E, listener: Listener<E, EventMap[E]>): Subscription {
    const eventListeners = this.listeners.get(event) ?? new Set();
    if (eventListeners.size === 0) {
      this.listeners.set(event, eventListeners);
    }
    eventListeners.add(listener as Listener<unknown, unknown>);

    return {
      remove: () => {
        this.removeListener(event, listener);
      },
    };
  }

  addListeners<E extends keyof EventMap>(events: readonly E[], listener: Listener<E, EventMap[E]>): Subscription {
    const subscriptions = events.map((event) => this.addListener(event, listener));
    return {
      remove: () => {
        subscriptions.forEach((s) => s.remove());
      },
    };
  }

  removeListener<E extends keyof EventMap>(event: E, listener: Listener<E, EventMap[E]>): boolean {
    const eventListeners = this.listeners.get(event);
    if (!eventListeners) {
      return false;
    }
    const deleted = eventListeners.delete(listener as Listener<unknown, unknown>);

    // If there's no more events in the set, we can remove it from the map to save memory
    if (eventListeners.size === 0) {
      this.listeners.delete(event);
    }

    return deleted;
  }

  removeListeners<E extends keyof EventMap>(events: readonly E[], listener: Listener<E, EventMap[E]>): boolean {
    return events.map((event) => this.removeListener(event, listener)).some((removed) => removed);
  }

  emit<E extends keyof EventMap>(event: E, data: EventMap[E], immediate = false): void {
    const performEmit = () => {
      const eventListeners = this.listeners.get(event);
      if (!eventListeners) {
        return;
      }
      eventListeners.forEach((listener) => listener(data, event));
    };

    if (immediate) {
      performEmit();
      return;
    }

    setTimeout(performEmit, 0);
  }
}

export interface NativeEventEmitter {
  addEventListener(type: string, listener: EventListener): void;
  removeEventListener(type: string, listener: EventListener): void;
}
export class NativeEventEmitterWrapper<EventMap extends Record<string, unknown> = Record<string, unknown>> {
  constructor(private readonly nativeEmitter: NativeEventEmitter) {}

  addListener<E extends keyof EventMap>(event: E, listener: Listener<E, EventMap[E]>): Subscription {
    const handler = (data: Event) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      listener(data as any, event as any);
    };

    this.nativeEmitter.addEventListener(String(event), handler);

    return {
      remove: () => {
        this.nativeEmitter.removeEventListener(String(event), handler);
      },
    };
  }

  addListeners<E extends keyof EventMap>(events: readonly E[], listener: Listener<E, EventMap[E]>): Subscription {
    const subscriptions = events.map((event) => this.addListener(event, listener));
    return {
      remove: () => {
        subscriptions.forEach((s) => s.remove());
      },
    };
  }
}
