import { styled } from '@linaria/react';

import { RMImage } from '@/components/RMImage/RMImage';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const Image = styled(RMImage)`
  border-radius: 24px;
  border: 1px solid var(--border-hairline);
  max-height: 397px;
  object-fit: contain;
  box-shadow: var(--elevation-bottom-3);

  body[data-mobile='true'] & {
    max-height: 16.8rem;
  }
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  margin-bottom: calc(var(--spacing-md) * -1);
  max-width: calc(100% - var(--spacing-2xl));
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-xl) var(--spacing-xl);
  background: var(--surface-brightest);
  border-radius: var(--radius-smooth);
  border: 1px solid var(--border-weak);

  body[data-mobile='true'] & {
    max-height: 410px;
    padding: var(--spacing-2xl) var(--spacing-md) var(--spacing-md) var(--spacing-md);
  }

  &[data-large='true'] {
    justify-content: center;
    align-items: center;
    max-height: 535px;
    height: 100%;
  }

  body[data-mobile='true'] &[data-large='true'] {
    max-height: 410px;
  }
`;
