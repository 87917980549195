export class RecordingInterruptedError extends Error {
  constructor(public reason: 'no-data-received' | 'recorder-interrupted' | 'queue-disconnected') {
    super('The recording has been interrupted, reason: ' + reason);
  }
}

export class InactiveMediaStreamError extends Error {
  constructor() {
    super('The media stream is inactive');
  }
}

export class MissingMediaTrackError extends Error {
  constructor(type: 'audio' | 'video') {
    super('The media stream does not contain a ' + type + ' track');
  }
}
