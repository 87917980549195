import { useCallback, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  InterviewManagerStateType,
  useInterviewError,
  useInterviewManager,
  useUploadProgress,
} from '@/modules/conversation-recorder/interview';
import { getRecordingRecoveryPath } from '@/modules/routing';

import { RecordUploading } from '../components/RecordUploading/RecordUploading';

export interface RecordUploadingContainerProps {
  onFinish: () => void;
}

export function RecordUploadingContainer({ onFinish }: RecordUploadingContainerProps) {
  const navigate = useNavigate();

  const interviewManager = useInterviewManager();
  const progress = useUploadProgress();
  const error = useInterviewError();
  const [searchParams] = useSearchParams();

  const handleGoToRecover = useCallback(() => {
    navigate(getRecordingRecoveryPath() + '?' + searchParams.toString());
  }, [navigate, searchParams]);

  const mountedAt = useRef(Date.now());
  useEffect(() => {
    return interviewManager.subscribeType(
      InterviewManagerStateType.Finished,
      () => {
        // Keep the user at least 3 seconds on this screen to avoid flashing
        const timeSpent = Date.now() - mountedAt.current;
        if (timeSpent > 3000) {
          onFinish();
          return;
        }
        setTimeout(onFinish, 3000 - timeSpent);
      },
      { immediate: true },
    );
  }, [interviewManager, onFinish]);

  return (
    <RecordUploading
      progress={progress.percentage}
      status={error !== null ? 'failed' : 'in-progress'}
      onGoToRecover={handleGoToRecover}
    />
  );
}
