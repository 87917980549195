import { ReactNode } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMText } from '@/components/RMText/RMText';

import { Container, Duration } from './RecordingIndicator.styles';

export interface RecordingIndicatorProps {
  Waveform: ReactNode;
  state: 'recording' | 'finished' | 'ready' | 'paused' | 'confirm';
}

export function RecordingIndicator({ Waveform, state }: RecordingIndicatorProps) {
  return (
    <Container bordered={state === 'recording' || state === 'paused'}>
      {state === 'paused' ? (
        <RMText type="sans" size="xs" color="on-surface-primary" bold>
          Paused
        </RMText>
      ) : (
        <>
          {Waveform}

          {state === 'recording' && (
            <Duration>
              <FontAwesomeIcon size="sm" icon={faCircle} className="animate-pulse" />

              <RMText type="serif" size="xs" color="recording" bold="medium">
                REC
              </RMText>
            </Duration>
          )}
        </>
      )}
    </Container>
  );
}
