import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled } from '@linaria/react';

export const StyledCircularProgress = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Logomark = styled.img`
  position: absolute;
`;

export const CheckIcon = styled(FontAwesomeIcon)`
  position: absolute;
`;
