import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';

import { ButtonContainer, CenteredText, Container, ContainerWrapper } from './RecordPermissionRejected.styles';

export interface RecordPermissionRejectedProps {
  type: 'audio' | 'video';
  onGoBack: () => void;
}

export function RecordPermissionRejected({ type, onGoBack }: RecordPermissionRejectedProps) {
  return (
    <Container>
      <ContainerWrapper>
        <CenteredText type="serif" size="l" color="on-surface-primary">
          <div>Oh no!</div>
          {type === 'audio' && <div>It looks like we can't access your microphone.</div>}
          {type === 'video' && <div>It looks like we can't access your camera.</div>}
        </CenteredText>
        <ButtonContainer>
          <RMButton
            fullWidth={true}
            background="primary"
            onClick={() => open('https://remento.typeform.com/to/ZVET3Qgc', '_blank')}
          >
            See how to turn on {type === 'audio' ? 'mic' : 'camera'}
          </RMButton>
          <RMButton fullWidth={true} leftIcon={faArrowLeft} background="outlined" onClick={onGoBack}>
            Try again
          </RMButton>
        </ButtonContainer>
      </ContainerWrapper>
    </Container>
  );
}
