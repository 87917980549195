import { ImageAssetAlternativeType } from '@remento/types/alternative';

import { usePromptFirstQuestionQuery, usePromptImageUrl, usePromptQuery } from '@/services/api/project';

import { RecordingPrompt } from '../components/RecordingPrompt/RecordingPrompt';
import { useRequesterName } from '../hooks/record-person-name';

export interface RecordingPromptContainerProps {
  promptId: string;
  fullscreenEnabled?: boolean;
}

export function RecordingPromptContainer({ promptId, fullscreenEnabled }: RecordingPromptContainerProps) {
  // Queries
  const promptQuery = usePromptQuery(promptId);
  const questionQuery = usePromptFirstQuestionQuery(promptId);
  const imageUrl = usePromptImageUrl(promptQuery.data ?? null, ImageAssetAlternativeType.MEDIUM);

  // Local state
  const askedBy = useRequesterName(promptId, 'You');

  return (
    <RecordingPrompt
      question={questionQuery.data?.text ?? ''}
      photoSrc={imageUrl}
      askedBy={askedBy ?? 'Someone'}
      fullscreenEnabled={fullscreenEnabled}
    />
  );
}
