import { StateMachine } from '../state-machine';
import { matchState, StateMatcher } from '../state-matcher';

import { RecorderMediaStreamState, RecorderMediaStreamStateType } from './media-stream.types';

export class RecorderMediaStream extends StateMachine<RecorderMediaStreamState> {
  constructor() {
    super({
      type: RecorderMediaStreamStateType.Empty,
    });
  }

  setStream(stream: MediaStream): void {
    const state = this.getState();
    if (state.type !== RecorderMediaStreamStateType.Empty) {
      throw new Error('MediaStreamState is not empty');
    }

    this.setState({
      type: RecorderMediaStreamStateType.Streaming,
      stream,
    });

    // Listen to the end of the stream
    const tracks = stream.getTracks();
    const endedListener = () => {
      if (this.getState().type !== RecorderMediaStreamStateType.Finished) {
        this.setState({
          type: RecorderMediaStreamStateType.Finished,
        });

        for (const track of tracks) {
          track.removeEventListener('ended', endedListener);
        }
      }
    };

    for (const track of stream.getTracks()) {
      track.addEventListener('ended', endedListener);
    }
  }

  stop(): void {
    const state = matchState(this.getState())
      .withType(RecorderMediaStreamStateType.Streaming, StateMatcher.returnState)
      .withType(RecorderMediaStreamStateType.Finished, StateMatcher.returnNull)
      .otherwise(() => {
        throw new Error('The RecorderMediaStream is not in a valid state to be stopped');
      });
    if (state === null) {
      console.warn('The RecorderMediaStream is already stopped');
      return;
    }

    for (const track of state.stream.getTracks()) {
      track.stop();
    }
  }
}
