import { ReactNode } from 'react';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

import {
  BackgroundImage,
  Content,
  ContentText,
  Footer,
  HeaderText,
  StyledProjectRecordCard,
} from './ProjectRecordCard.styles';

export interface RecordCardProps {
  header?: string | ReactNode;
  content: string | ReactNode;
  backgroundUrl?: string | null;
  fullscreenBackgroundUrl?: string | null;
  actionLabel: string;
  actionIcon?: IconDefinition;
  onAction: () => void;
}

export function RecordCard({
  header,
  content,
  backgroundUrl,
  fullscreenBackgroundUrl,
  actionIcon,
  actionLabel,
  onAction,
}: RecordCardProps) {
  const isMobile = useIsMobileViewport();

  return (
    <StyledProjectRecordCard>
      <Content>
        {backgroundUrl != null && (
          <BackgroundImage src={backgroundUrl} fullscreenSrc={fullscreenBackgroundUrl} fullscreenEnabled />
        )}
        {header != null && (
          <HeaderText
            type="sans"
            size={isMobile ? 'xxs' : 'xs'}
            color={backgroundUrl != null ? 'inverse-on-surface-secondary' : 'on-surface-tertiary'}
            align="center"
            bold
            trackedOutCaps
          >
            {header}
          </HeaderText>
        )}
        {typeof content === 'string' ? (
          <ContentText
            type="serif"
            size={isMobile ? 'l' : 'xl'}
            color={backgroundUrl != null ? 'inverse-on-surface-primary' : 'on-surface-primary'}
            align="center"
          >
            {content}
          </ContentText>
        ) : (
          content
        )}
      </Content>

      <Footer>
        <RMButton background="primary" size="massive" leftIcon={actionIcon} fullWidth onClick={onAction}>
          {actionLabel}
        </RMButton>
      </Footer>
    </StyledProjectRecordCard>
  );
}
