import { ReactNode, useCallback, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import lottie, { AnimationItem } from 'lottie-web';

import ConfettiAnimation from '@/assets/confetti.lottie.json';
import SpinnerLottieAnimation from '@/assets/spinner.lottie.json';
import { RMText } from '@/components/RMText/RMText';

import {
  ConfettiPage,
  ConfettiTextContainer,
  LottieContainer,
  LottieSpinner,
  ProjectMembersWrapper,
} from './RecordCelebration.styles';

export interface RecordCelebrationProps {
  onFinishCelebration: () => void;
  ProjectMembersAvatarList: ReactNode;
}

export function RecordCelebration({ onFinishCelebration, ProjectMembersAvatarList }: RecordCelebrationProps) {
  const animationRef = useRef<AnimationItem | null>();
  const lottieRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node === null) {
        animationRef.current?.destroy();
        animationRef.current = null;
        return;
      }

      const animation = lottie.loadAnimation({
        container: node,
        loop: false,
        autoplay: true,
        animationData: ConfettiAnimation,
        renderer: 'svg',
        rendererSettings: {
          preserveAspectRatio: 'xMidYMax slice',
        },
      });
      animationRef.current = animation;
      const unsubscribe = animation.addEventListener('complete', () => {
        onFinishCelebration();
        unsubscribe();
      });
    },
    [onFinishCelebration],
  );

  const handleAnimationEnd = useCallback(() => {
    setTimeout(() => {
      onFinishCelebration();
    }, 5000);
  }, [onFinishCelebration]);

  return (
    <AnimatePresence>
      <ConfettiPage key="confetti" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
        <LottieContainer ref={lottieRef} />
        <ConfettiTextContainer>
          <RMText type="serif" size="l" color="on-surface-primary">
            Way to go!
          </RMText>
          <ProjectMembersWrapper
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 2 }}
            onAnimationEnd={handleAnimationEnd}
          >
            {ProjectMembersAvatarList}
            <RMText type="sans" size="xs" color="on-surface-primary" align="center">
              We’ve shared your response with people in the project
            </RMText>
            <LottieSpinner loop animationData={SpinnerLottieAnimation} />
          </ProjectMembersWrapper>
        </ConfettiTextContainer>
      </ConfettiPage>
    </AnimatePresence>
  );
}
