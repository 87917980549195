import { useMemo } from 'react';

import { useIsPaused, useIsRecording } from '../../conversation-recorder/interview';
import { RecordingIndicator } from '../components/RecordingIndicator/RecordingIndicator';

import { RecordWaveformContainer } from './RecordWaveform.container';

export interface RecordingIndicatorContainerProps {
  widgetState: 'recording' | 'finished' | 'ready' | 'paused' | 'confirm';
  audioStream: MediaStream;
}

export function RecordingIndicatorContainer({ widgetState, audioStream }: RecordingIndicatorContainerProps) {
  const isRecording = useIsRecording();
  const isPaused = useIsPaused();

  const state = useMemo(() => {
    if (widgetState === 'ready') {
      return 'ready';
    }

    if (isRecording) {
      return 'recording';
    }

    if (isPaused) {
      return 'paused';
    }

    return widgetState;
  }, [isPaused, isRecording, widgetState]);

  return <RecordingIndicator Waveform={<RecordWaveformContainer size="small" stream={audioStream} />} state={state} />;
}
