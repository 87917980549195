import type RecordRTC from 'recordrtc';

import { RecorderMediaStream } from '../media-stream/media-stream';

export type RecorderOptions = Omit<RecordRTC.Options, 'ondataavailable'>;

export enum MediaRecorderStateType {
  Empty = 'Empty',
  Initializing = 'Initializing',
  Ready = 'Ready',
  Recording = 'Recording',
  Pausing = 'Pausing',
  Paused = 'Paused',
  Finishing = 'Finishing',
  Finished = 'Finished',
  Error = 'Error',
  Destroyed = 'Destroyed',
}

export interface MediaRecorderEmptyState {
  type: MediaRecorderStateType.Empty;
}

export interface MediaRecorderInitializingState {
  type: MediaRecorderStateType.Initializing;
  stream: RecorderMediaStream;
  recorderOptions: RecorderOptions;
}

export interface MediaRecorderReadyState {
  type: MediaRecorderStateType.Ready;
  stream: RecorderMediaStream;
  recorderOptions: RecorderOptions;
}

export interface MediaRecorderRecordingState {
  type: MediaRecorderStateType.Recording;
  stream: RecorderMediaStream;
  recorder: RecordRTC;
  recorderOptions: RecorderOptions;
  pauseCount: number;
  duration: number;
  startTimestamp: number;
}

export interface MediaRecorderPausingState {
  type: MediaRecorderStateType.Pausing;
  stream: RecorderMediaStream;
  recorder: RecordRTC;
  recorderOptions: RecorderOptions;
  pauseCount: number;
  duration: number;
  startTimestamp: number;
}

export interface MediaRecorderPausedState {
  type: MediaRecorderStateType.Paused;
  stream: RecorderMediaStream;
  recorderOptions: RecorderOptions;
  pauseCount: number;
  duration: number;
}

export interface MediaRecorderFinishingState {
  type: MediaRecorderStateType.Finishing;
  recorder: RecordRTC;
  duration: number;
}

export interface MediaRecorderFinishedState {
  type: MediaRecorderStateType.Finished;
  duration: number;
}

export interface MediaRecorderErrorState {
  type: MediaRecorderStateType.Error;
}

export interface MediaRecorderDestroyedState {
  type: MediaRecorderStateType.Destroyed;
}

export type MediaRecorderState =
  | MediaRecorderEmptyState
  | MediaRecorderInitializingState
  | MediaRecorderReadyState
  | MediaRecorderRecordingState
  | MediaRecorderPausingState
  | MediaRecorderPausedState
  | MediaRecorderFinishingState
  | MediaRecorderFinishedState
  | MediaRecorderErrorState
  | MediaRecorderDestroyedState;
