import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import arrowAnimationData from '@/@assets/arrow-down-lottie-animation.json';
import CamPermIcon from '@/assets/icons/cam-perm.svg';
import MicPermIcon from '@/assets/icons/mic-perm.svg';
import spinnerAnimationData from '@/assets/spinner.lottie.json';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';
import { isBrowser } from '@/hooks/useIsBrowser';
import { isMobileViewport } from '@/hooks/useIsMobileViewport';
import { BackButtonWrapper } from '@/modules/recording/components/RecordReady/RecordReady.styles';

import { RecordingLayout } from '../../layouts/RecordingLayout';
import { BrowserNotSupportedModal } from '../BrowserNotSupportedModal/BrowserNotSupportedModal';

import {
  AllowInstructions,
  ArrowAnimation,
  CameraPlaceholder,
  CameraPlaceholderText,
  Content,
  Footer,
  LoadingContent,
  SpinnerAnimation,
} from './RecordPermission.styles';

export interface RecordPermissionProps {
  type: 'audio' | 'video';
  host: string;
  link: string;
  askingPermission: boolean;
  loadingPermission: boolean;
  browserNotSupported: boolean;
  onRequestPermissions: () => void;
  onBack: () => void;
}

export function RecordPermission({
  type,
  host,
  link,
  askingPermission = false,
  loadingPermission = false,
  browserNotSupported,
  onRequestPermissions,
  onBack,
}: RecordPermissionProps) {
  const mobile = isMobileViewport();
  const isSafari = isBrowser('safari');

  return (
    <RecordingLayout.Root
      Header={
        <RecordingLayout.Header>
          <BackButtonWrapper>
            <RMIconButton
              icon={faChevronLeft}
              size="xl"
              tooltip={null}
              backgroundColor="transparent"
              onClick={onBack}
            />
          </BackButtonWrapper>
        </RecordingLayout.Header>
      }
    >
      <Content>
        {loadingPermission ? (
          <LoadingContent>
            <SpinnerAnimation animationData={spinnerAnimationData} loop />
          </LoadingContent>
        ) : askingPermission ? (
          <LoadingContent>
            <div />
            <SpinnerAnimation animationData={spinnerAnimationData} loop />
            <AllowInstructions data-safari={isSafari}>
              {(mobile || isSafari) && <ArrowAnimation animationData={arrowAnimationData} loop />}
              <RMText align="center" size={mobile ? 'm' : 's'} type="sans" color="on-surface-primary">
                Press “
                <RMText size={mobile ? 'm' : 's'} bold type="sans" color="on-surface-primary">
                  Allow
                </RMText>
                ” to turn on your {type === 'audio' ? 'microphone' : 'camera'}.
              </RMText>
            </AllowInstructions>
          </LoadingContent>
        ) : (
          <>
            <CameraPlaceholder>
              <img src={type === 'audio' ? MicPermIcon : CamPermIcon} alt="" />
              <RMText align="center" size={mobile ? 'l' : 'xl'} type="serif" color="on-surface-primary">
                {type === 'audio' && `Please allow microphone access so ${host} can hear you.`}
                {type === 'video' && `Please allow camera access so ${host} can see and hear you`}
              </RMText>
              <CameraPlaceholderText align="center" size="s" type="sans" color="on-surface-primary">
                {type === 'audio' && 'To record your stories, we need temporary access to your microphone.'}
                {type === 'video' && 'To record your stories, we need temporary access to your camera and microphone.'}
              </CameraPlaceholderText>
            </CameraPlaceholder>

            <Footer>
              <RMButton size="massive" background="primary" fullWidth onClick={onRequestPermissions}>
                {type === 'audio' ? 'Turn on microphone' : 'Turn on camera'}
              </RMButton>
            </Footer>
          </>
        )}
      </Content>

      <BrowserNotSupportedModal open={browserNotSupported} link={link} />
    </RecordingLayout.Root>
  );
}
