import Dexie from 'dexie';

import { captureException } from './captureException';
import { secureUuid } from './uuid';

class TestDexieDatabase extends Dexie {
  readonly test!: Dexie.Table<{ id: string; test: Blob }>;

  constructor() {
    super('TestDatabase');

    this.version(1).stores({
      test: '++id, test',
    });
  }
}

export async function hasIndexedDbSupport() {
  try {
    const testDatabase = new TestDexieDatabase();
    await testDatabase.test.add({
      id: secureUuid(),
      test: new Blob([new ArrayBuffer(16)], { type: 'application/octet-stream' }),
    });
    await testDatabase.test.clear();

    return true;
  } catch (error) {
    captureException(error, true);

    return false;
  }
}
