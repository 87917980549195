import { styled } from '@linaria/react';

export const RecordingTime = styled.div`
  text-align: center;
  font-family: 'RobotoMono';
  color: var(--on-surface-tertiary);
  font-size: 0.75rem;
  font-weight: 400;
  font-size: 12px;
`;
