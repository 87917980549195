import { styled } from '@linaria/react';

export const StyledProjectRecordCustomizePage = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  max-width: 480px;
  display: flex;
  flex-flow: column;
  padding-bottom: var(--spacing-xl);

  body[data-mobile='true'] & {
    padding-left: var(--spacing-lg);
    padding-right: var(--spacing-lg);
  }
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
  flex: 0 0 6rem;
  margin: 0 calc(var(--spacing-md) * -1);

  body[data-mobile='true'] & {
    height: 4rem;
    flex: 0 0 4rem;
  }
`;

export const Hidden = styled.div`
  opacity: 0;
  pointer-events: none;
`;

export const Content = styled.div`
  padding-top: var(--spacing-md);
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;
