export class AsyncLock {
  private currentLocks = 0;
  private waitQueue: (() => void)[] = [];

  constructor(private readonly maxLocks = 1) {
    if (maxLocks < 1 || !Number.isInteger(maxLocks)) {
      throw new Error('AsyncLock requires maxLocks to be a positive integer');
    }
  }

  acquire(timeout?: number): Promise<void> {
    if (this.currentLocks < this.maxLocks) {
      this.currentLocks += 1;
      return Promise.resolve();
    } else {
      if (timeout !== undefined && timeout > 0) {
        let timeoutId: ReturnType<typeof setTimeout> | undefined;
        let queuedResolve: (() => void) | undefined;

        const resolveFn = () => {
          clearTimeout(timeoutId);
          queuedResolve?.();
        };

        const timeoutPromise = new Promise<void>((_, reject) => {
          timeoutId = setTimeout(() => {
            this.waitQueue = this.waitQueue.filter((resolve) => resolve !== resolveFn);
            reject(new Error('AsyncLock acquire timed out'));
          }, timeout);
        });

        return Promise.race([
          timeoutPromise,
          new Promise<void>((resolve) => {
            queuedResolve = resolve;
            this.waitQueue.push(resolveFn);
          }),
        ]);
      }
      return new Promise((resolve) => {
        this.waitQueue.push(resolve);
      });
    }
  }

  release(): void {
    const next = this.waitQueue.shift();
    if (next) {
      next();
    } else {
      this.currentLocks -= 1;
    }
  }
}
