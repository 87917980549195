import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
`;

export const Text = styled(motion.span)``;

export const emptyLineClassName = css`
  flex-basis: 100%;
`;
