import { WebappAnalyticsService } from '@/services/analytics/webapp-analytics/webapp-analytics.types';

export function listenToPermissionChanges(analytics: WebappAnalyticsService): () => void {
  // https://github.com/microsoft/TypeScript/issues/33923
  const cameraPermissionName = 'camera' as PermissionName;
  const microphonePermissionName = 'microphone' as PermissionName;

  let aborted = false;

  let cameraPermissionStatus: PermissionStatus | null = null;
  let microphonePermissionStatus: PermissionStatus | null = null;

  let cameraPermissionState: PermissionState | null = null;
  let microphonePermissionState: PermissionState | null = null;

  const onChangePermissionStatus = (permissionEvent: Event) => {
    const newStatus = permissionEvent.target as PermissionStatus;
    const newState = newStatus.state;

    switch (newStatus.name) {
      case 'video_capture': {
        if (cameraPermissionState === 'prompt' && (newState === 'denied' || newState === 'granted')) {
          analytics.onCameraPermissionShown();
        }

        cameraPermissionState = newState;

        if (newState === 'granted') {
          analytics.onCameraPermissionGranted();
        } else if (newState === 'denied') {
          analytics.onCameraPermissionDenied();
        }
        break;
      }
      case 'audio_capture': {
        if (microphonePermissionState === 'prompt' && (newState === 'denied' || newState === 'granted')) {
          analytics.onMicrophonePermissionShown();
        }

        microphonePermissionState = newState;

        if (newState === 'granted') {
          analytics.onMicrophonePermissionGranted();
        } else if (newState === 'denied') {
          analytics.onMicrophonePermissionDenied();
        }
        break;
      }
    }
  };

  navigator.permissions
    ?.query({ name: cameraPermissionName })
    .then((permissionStatus) => {
      if (aborted) {
        return;
      }
      cameraPermissionStatus = permissionStatus;
      cameraPermissionState = permissionStatus.state;
      permissionStatus.addEventListener('change', onChangePermissionStatus);
    })
    .catch(() => {
      // The camera permission query is not available in some browsers
    });

  navigator.permissions
    ?.query({ name: microphonePermissionName })
    .then((permissionStatus) => {
      if (aborted) {
        return;
      }

      microphonePermissionStatus = permissionStatus;
      microphonePermissionState = permissionStatus.state;
      permissionStatus.addEventListener('change', onChangePermissionStatus);
    })
    .catch(() => {
      // The microphone permission query is not available in some browsers
    });

  return () => {
    aborted = true;
    cameraPermissionStatus?.removeEventListener('change', onChangePermissionStatus);
    microphonePermissionStatus?.removeEventListener('change', onChangePermissionStatus);
  };
}
