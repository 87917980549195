import { styled } from '@linaria/react';

export const Container = styled.div<{ bordered: boolean }>`
  padding: var(--spacing-sm) var(--spacing-md);
  border-radius: var(--radius-full);
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
  border: ${(props) => (props.bordered ? '1px solid var(--border-hairline)' : 'unset')};
  height: 40px;
  width: fit-content;
  margin: 0 auto;
`;

export const Duration = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  svg {
    width: 0.5rem;
    height: 0.5rem;
    transform: translateY(1px);
    color: var(--recording);
  }

  > span {
    font-variant-numeric: tabular-nums;
    font-family: 'RobotoMono';
  }
`;
