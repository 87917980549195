import { useCallback, useState } from 'react';
import { faMicrophone, faVideo } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';

export interface RecordingModeDialogProps {
  open: boolean;
  onChooseMode: (mode: 'audio' | 'video') => void;
  onClose: () => void;
}

export function RecordingModeDialog({ open, onChooseMode, onClose }: RecordingModeDialogProps) {
  const [loading, setLoading] = useState<'audio' | 'video' | null>(null);

  const handleChooseMode = useCallback(
    async (mode: 'audio' | 'video') => {
      try {
        setLoading(mode);
        await onChooseMode(mode);
      } finally {
        setLoading(null);
      }
    },
    [onChooseMode],
  );

  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header title="Choose your recording mode" rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Footer>
          <RMButton
            leftIcon={faMicrophone}
            fullWidth
            loading={loading === 'audio'}
            disabled={loading !== null}
            onClick={() => handleChooseMode('audio')}
          >
            Audio
          </RMButton>
          <RMButton
            leftIcon={faVideo}
            background="primary"
            fullWidth
            loading={loading === 'video'}
            disabled={loading !== null}
            onClick={() => handleChooseMode('video')}
          >
            Video
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
