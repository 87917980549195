import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';

import { DelayedText, DelayedTextEntry } from '@/components/DelayedText/DelayedText';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { BrowserNotSupportedModal } from '@/modules/recording/components/BrowserNotSupportedModal/BrowserNotSupportedModal';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { getRecordingTypeSelectionPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { useCurrentUrlWithTokens } from '@/services/api/authorization';
import { useProjectQuery } from '@/services/api/project';
import { hasIndexedDbSupport } from '@/utils/hasIndexedDbSuport';

import { CloseButton, Content, Footer, LearnMoreDialogContent, Message } from './RecordingIntroPage.styles';

function RecordingIntro() {
  const { storytellingAnalyticsService } = useServices();

  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  const [step, setStep] = useState<'greeting' | 'welcome'>('greeting');

  const user = useUser();
  const projectQuery = useProjectQuery(params.projectId ?? null);
  const personId = projectQuery.data?.subjectPersonIds?.[0];

  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);
  const [isIncognito, setIsIncognito] = useState<boolean>();

  const loadingText = useMemo(() => {
    return '. . . . . . .'.split(' ');
  }, []);

  const greetingText = useMemo<DelayedTextEntry | null>(() => {
    const currentDayHour = new Date().getHours();
    let dayGreeting: string;
    if (currentDayHour >= 17 || currentDayHour < 3) {
      dayGreeting = 'Good evening';
    } else if (currentDayHour >= 3 && currentDayHour < 12) {
      dayGreeting = 'Good morning';
    } else {
      dayGreeting = 'Good afternoon';
    }

    return `${dayGreeting}.`;
  }, []);

  const welcomeText = useMemo<DelayedTextEntry>(() => {
    return 'It’s time to share this week’s reflection.';
  }, []);

  const isLoading = greetingText == null || isIncognito === undefined;

  const handleContinue = useCallback(() => {
    navigate(getRecordingTypeSelectionPath(params.projectId ?? '', params.promptId ?? '', searchParams));
    storytellingAnalyticsService.onStorytellingResponseStarted(
      personId === user?.personId ? 'sender' : 'recipient',
      referrer,
    );
  }, [navigate, params, personId, searchParams, storytellingAnalyticsService, user?.personId, referrer]);

  const handleLearnMore = useCallback(() => {
    setLearnMoreModalOpen(true);
    storytellingAnalyticsService.onStorytellingLearnMorePressed(
      personId === user?.personId ? 'sender' : 'recipient',
      referrer,
    );
  }, [personId, storytellingAnalyticsService, user?.personId, referrer]);

  // Analytics
  useEffect(() => {
    if (!personId) {
      return;
    }

    storytellingAnalyticsService.onStorytellingArrived(personId === user?.personId ? 'sender' : 'recipient', referrer);
  }, [personId, storytellingAnalyticsService, user?.personId, referrer]);

  // Check if is using incognito
  const recordingLink = useCurrentUrlWithTokens();

  useEffect(() => {
    const checkIsIncognito = async () => {
      const supportsIndexedDb = await hasIndexedDbSupport();
      setIsIncognito(!supportsIndexedDb);
    };

    checkIsIncognito();
  }, []);

  if (isIncognito) {
    return (
      <RecordingLayout.Root dark Header={<RecordingLayout.Header dark />}>
        <BrowserNotSupportedModal open link={recordingLink} />
      </RecordingLayout.Root>
    );
  }

  return (
    <RecordingLayout.Root dark>
      <RecordingLayout.Header dark showLogo />
      <Content>
        <div />

        <Message>
          {isLoading && <DelayedText text={loadingText} />}

          {!isLoading && (step === 'greeting' || step === 'welcome') && (
            <DelayedText text={greetingText} onAnimationEnd={() => setTimeout(() => setStep('welcome'), 1500)} />
          )}

          {step === 'welcome' && <DelayedText text={welcomeText} />}
        </Message>

        <motion.div
          style={{ width: '100%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: step === 'welcome' ? 1 : 0 }}
          transition={{ delay: 2.5, duration: 0.3 }}
        >
          <Footer>
            <RMButton background="gradient" size="massive" fullWidth onClick={handleContinue}>
              Continue
            </RMButton>

            <RMButton color="secondary" background="transparent" fullWidth onClick={handleLearnMore}>
              Learn more
            </RMButton>
          </Footer>
        </motion.div>
      </Content>

      <RMDialog.Root open={learnMoreModalOpen} onClose={() => setLearnMoreModalOpen(false)}>
        <LearnMoreDialogContent>
          <CloseButton
            icon={faClose}
            tooltip={null}
            color="white"
            backgroundColor="white"
            stateColor="lighten-white"
            onClick={() => setLearnMoreModalOpen(false)}
          />
          <iframe
            src="https://player.vimeo.com/video/877230338?h=57d8b15b79&badge=0&autopause=0&quality_selector=1&player_id=0&app_id=58479"
            width="100%"
            height="100%"
            allow="autoplay; fullscreen; picture-in-picture"
          />
        </LearnMoreDialogContent>
      </RMDialog.Root>
    </RecordingLayout.Root>
  );
}

export function RecordingIntroPage() {
  return (
    <RementoPage type="record" loader={<RecordingLayout.Root dark Header={<RecordingLayout.Header dark />} />}>
      <RecordingIntro />
    </RementoPage>
  );
}
