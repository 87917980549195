import { styled } from '@linaria/react';

import { RMText } from '@/components/RMText/RMText';

export const Content = styled.div`
  height: 100%;
  max-width: 33.25rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  gap: var(--spacing-2xl);
`;

export const CenteredText = styled(RMText)`
  text-align: center;
  margin: 0 var(--spacing-md);
`;

export const ButtonWrapper = styled.div`
  width: 112px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const VideoContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-height: 26rem;
  background: var(--surface-dim);
  border-radius: var(--radius-smooth);
  border: 1px solid var(--border-weak);
  aspect-ratio: 1;

  :after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateX(-100%);
    content: ' ';
    background: linear-gradient(270deg, #eae8e1 26.76%, #f7f6f3 48.81%, #eae8e1 73.81%);
    animation: shimmer 1.5s infinite ease-in-out;

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
`;

export const Video = styled.video`
  position: relative;
  width: 100%;
  aspect-ratio: 1;
  object-fit: cover;
  z-index: 1;
`;

export const WaveformContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 34px;
  width: 100%;
  max-height: 26rem;
  aspect-ratio: 1;
  border-radius: var(--radius-smooth);
  border: 1px solid var(--border-weak);
  background: var(--surface-dim);
`;

export const Footer = styled.div`
  margin-top: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);

  padding: var(--spacing-md);
  body[data-mobile='true'] & {
    padding: 0;
  }
`;

export const BackButtonWrapper = styled.div`
  width: 7rem;
`;
