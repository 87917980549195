import isEqual from 'react-fast-compare';
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector';

import { StateMachine, SubscribeSelector } from './state-machine';

/**
 * A selector function that extracts the 'type' property from the state object.
 *
 * @param - The state object from which to extract the 'type' property.
 * @returns - The value of the 'type' property from the state object.
 */
function typeSelector<T extends { type: string }>(state: T): T['type'] {
  return state.type;
}

/**
 * Returns a specific slice of the state from the given StateMachine and subscribes to its changes.
 *
 * @param sm - The StateMachine to retrieve the state slice from and subscribe to.
 * @param selector - A selector function to obtain the desired state slice.
 * @returns - The selected state slice from the StateMachine.
 */
export function useStateSlice<T extends { type: string }, U>(
  sm: StateMachine<T>,
  selector: SubscribeSelector<T, U>,
): U {
  return useSyncExternalStoreWithSelector(
    sm.subscribe.bind(sm),
    sm.getState.bind(sm),
    sm.getState.bind(sm),
    selector,
    isEqual,
  );
}

/**
 * Returns the current state type from the given StateMachine.
 *
 * @param sm - The StateMachine to retrieve the state type from.
 * @returns - The current state type from the StateMachine.
 */
export function useStateType<T extends { type: string }>(sm: StateMachine<T>): T['type'] {
  return useStateSlice(sm, typeSelector);
}
