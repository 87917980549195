import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { animate, AnimatePresence } from 'framer-motion';

import LogoSrc from '@/@assets/logomark-yellow.svg';
import { RMButton } from '@/components/RMButton/RMButton';
import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  CenteredText,
  Logo,
  SharedPage,
  SharedPageBody,
  SharedPageFooter,
  TimePartContainer,
  TimesContainer,
  TimeText,
} from '@/modules/recording/components/RecordCelebration/RecordCelebration.styles';
import { getProjectRecordPath, getStoriesPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';

interface RecordingFinishProps {
  projectId: string;
}

function RecordingFinish({ projectId }: RecordingFinishProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer');

  const { recordingSessionRepository, storytellingAnalyticsService } = useServices();

  const user = useUser();
  const [openSignInDialog, setOpenSignInDialog] = useState(false);

  const handleViewStories = useCallback(() => {
    if (user === null) {
      setOpenSignInDialog(true);
      return;
    }

    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');

    storytellingAnalyticsService.onStorytellingActionPerformed('view-stories', referrer);

    navigate(getStoriesPath(projectId ?? '', newSearchParams));
  }, [user, searchParams, storytellingAnalyticsService, navigate, projectId, referrer]);

  const handleRecordAnotherStory = useCallback(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('type');

    navigate(getProjectRecordPath(projectId, 'recording-finish', newSearchParams));
  }, [searchParams, navigate, projectId]);

  const handleConfirmSignIn = useCallback(async () => {
    navigate(getStoriesPath(projectId));
  }, [navigate, projectId]);
  const isMobile = useIsMobileViewport();

  const [showViewButton, setShowViewButton] = useState(false);

  const totalRecordedTime = useMemo(
    () => recordingSessionRepository.getTotalProjectRecordingTime() ?? 0,
    [recordingSessionRepository],
  );

  const secondsRef = useCallback(
    (node: HTMLSpanElement | null) => {
      if (!node) {
        return;
      }

      const seconds = totalRecordedTime % 60;
      animate(0, seconds, {
        delay: 0.2,
        duration: 1,
        onUpdate(value) {
          node.innerText = value.toFixed().padStart(2, '0');
        },
        onComplete() {
          setTimeout(() => {
            setShowViewButton(true);
          }, 250);
        },
      });
    },
    [totalRecordedTime],
  );
  const minutesRef = useCallback(
    (node: HTMLSpanElement | null) => {
      if (!node) {
        return;
      }

      const minutes = Math.floor((totalRecordedTime % 3600) / 60);
      animate(0, minutes, {
        delay: 0.2,
        duration: 1,
        onUpdate(value) {
          node.innerText = value.toFixed().padStart(2, '0');
        },
      });
    },
    [totalRecordedTime],
  );
  const hoursRef = useCallback(
    (node: HTMLSpanElement | null) => {
      if (!node) {
        return;
      }

      const hours = Math.floor(totalRecordedTime / 3600);
      animate(0, hours, {
        delay: 0.2,
        duration: 1,
        onUpdate(value) {
          node.innerText = value.toFixed().padStart(2, '0');
        },
      });
    },
    [totalRecordedTime],
  );

  return (
    <>
      <AnimatePresence>
        <SharedPage key="shared" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Logo src={LogoSrc} />
          <RMSpacer direction="column" spacing="xl" />
          <CenteredText
            type="serif"
            size={isMobile ? 'l' : 'xl'}
            lineHeight={isMobile ? 'l' : 'xl'}
            color="inverse-on-surface-primary"
          >
            Congrats on another story shared with Remento!
          </CenteredText>
          <SharedPageBody>
            <RMSpacer direction="column" spacing="xl" />
            <CenteredText type="sans" size="xs" color="inverse-on-surface-primary">
              Total recording time:
            </CenteredText>

            <RMSpacer direction="column" spacing={isMobile ? 'md' : 'xl'} />
            <TimesContainer>
              <TimePartContainer>
                <TimeText ref={hoursRef}>00</TimeText>
                <RMText type="sans" size="xxs" color="inverse-on-surface-secondary" bold trackedOutCaps>
                  HOURS
                </RMText>
              </TimePartContainer>
              <TimePartContainer>
                <TimeText ref={minutesRef}>00</TimeText>
                <RMText type="sans" size="xxs" color="inverse-on-surface-secondary" bold trackedOutCaps>
                  MINUTES
                </RMText>
              </TimePartContainer>
              <TimePartContainer>
                <TimeText ref={secondsRef}>00</TimeText>
                <RMText type="sans" size="xxs" color="inverse-on-surface-secondary" bold trackedOutCaps>
                  SECONDS
                </RMText>
              </TimePartContainer>
            </TimesContainer>
            <RMSpacer direction="column" spacing="5xl" />
          </SharedPageBody>

          <SharedPageFooter initial={{ translateY: '100%' }} animate={{ translateY: showViewButton ? 0 : '100%' }}>
            <RMButton size="massive" background="gradient" fullWidth onClick={handleRecordAnotherStory}>
              Record another story
            </RMButton>
            <RMButton background="transparent" color="secondary" fullWidth onClick={handleViewStories}>
              View my stories
            </RMButton>
          </SharedPageFooter>
        </SharedPage>
      </AnimatePresence>
      <RMConfirmationModal
        open={openSignInDialog}
        type="primary"
        title="Sign in to view stories"
        message="Watch this story and the rest of your memories by signing into Remento."
        confirmLabel="Sign in"
        cancelLabel={null}
        onConfirm={handleConfirmSignIn}
        onClose={() => setOpenSignInDialog(false)}
      />
    </>
  );
}

export function RecordingFinishPage() {
  const params = useParams();

  return (
    <RementoPage type="empty">
      <RecordingFinish projectId={params.projectId ?? ''} />
    </RementoPage>
  );
}
