export const MINIMUM_RECORDING_DURATION = 15;

export type ErrorOrigin = 'manager' | 'queue-synchronizer' | 'media-recorder';

export type RecordingType = 'video' | 'audio';

export enum InterviewManagerStateType {
  Empty = 'Empty',
  Initializing = 'Initializing',
  Pausing = 'Pausing',
  Paused = 'Paused',
  Recording = 'Recording',
  Finishing = 'Finishing',
  Finished = 'Finished',
  UnrecoverableError = 'UnrecoverableError',
  Destroyed = 'Destroyed',
}

export interface InterviewManagerRecording {
  recording: Blob;
  duration: number;
}

export interface InterviewManagerBaseState {
  sessionId: string;
  promptId: string;
  imageId: string | null;
  promptQuestion: string;
  recordings: InterviewManagerRecording[];
}

export interface InterviewManagerEmptyState {
  type: InterviewManagerStateType.Empty;
}

export interface InterviewManagerInitializingState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.Initializing;
}

export interface InterviewManagerPausingState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.Pausing;
  startTimestamp: number;
}

export interface InterviewManagerPausedState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.Paused;
}

export interface InterviewManagerRecordingState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.Recording;
  startTimestamp: number;
}

export interface InterviewManagerFinishingState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.Finishing;
}

export interface InterviewManagerFinishedState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.Finished;
}

export interface InterviewManagerUnrecoverableErrorState extends InterviewManagerBaseState {
  type: InterviewManagerStateType.UnrecoverableError;
  origin: ErrorOrigin;
  error: unknown;
}

export interface InterviewManagerDestroyedState extends Partial<InterviewManagerBaseState> {
  type: InterviewManagerStateType.Destroyed;
}

export type InterviewManagerState =
  | InterviewManagerEmptyState
  | InterviewManagerInitializingState
  | InterviewManagerPausingState
  | InterviewManagerPausedState
  | InterviewManagerRecordingState
  | InterviewManagerFinishingState
  | InterviewManagerFinishedState
  | InterviewManagerUnrecoverableErrorState
  | InterviewManagerDestroyedState;
