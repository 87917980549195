import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: column;
`;

export const RecordingTypesButtons = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-md);
  width: 100%;
`;

export const RecordingTypesContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-md);
  width: 100%;
  padding-top: var(--spacing-md);
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--spacing-md);
`;
