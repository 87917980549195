import React, { useMemo } from 'react';

import { TICKS_PER_SIZE } from './RecordWaveform.constants';
import { Container, Tick } from './RecordWaveform.styles';

interface RecordWaveformProps {
  samples: Uint8Array;
  size: 'large' | 'medium' | 'small';
  color?: 'blend-50' | 'blend-70';
}

export function RecordWaveform({ samples, size, color = 'blend-50' }: RecordWaveformProps): JSX.Element {
  const sensitivity = size === 'small' ? 26 : 62;
  const maxHeight = useMemo(() => sensitivity * Math.sin(0.5 * Math.PI) + 2, [sensitivity]);

  return (
    <Container height={maxHeight} size={size}>
      {Array.from(samples).map((d, i) => {
        const height = (d / 255) * sensitivity * Math.sin((i / TICKS_PER_SIZE[size]) * Math.PI) + 2;

        return <Tick size={size} key={i} style={{ height: `${height}px`, backgroundColor: `var(--color-${color})` }} />;
      })}
    </Container>
  );
}
