import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

export const Container = styled.div<{ disabled: boolean }>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-flow: column;
  gap: var(--spacing-2xl);
  overflow-y: hidden;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  touch-action: none;
`;

const pipSize = '5rem';
export const PipPlaceholder = styled.div`
  height: ${pipSize};
  width: ${pipSize};
  flex-shrink: 0;
`;

export const Pip = styled(motion.div)`
  position: absolute;
  left: 0;
  z-index: 9999;
`;

export const Controls = styled(motion.div)`
  width: 100%;

  body[data-mobile='true'] & {
    padding-right: var(--spacing-sm);
  }
`;

export const Row = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--spacing-md);
  position: absolute;
  bottom: 0;
`;

export const LottieWrapper = styled(motion.div)`
  position: absolute;
  width: 2.75rem;

  right: calc(50% - var(--spacing-md));
  &[data-video='true'] {
    // 50% of content - pipsize + gapSize
    right: calc(50% - ${pipSize} + var(--spacing-md));
  }

  // calculation lottie stay above the button and with correct margin
  top: calc((2.75rem + var(--spacing-sm)) * -1);

  body[data-mobile='true'] & {
    width: 2rem;
    top: calc((2rem + var(--spacing-sm)) * -1);
  }
`;

export const RecordingButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const RecordingButtonText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const PlaybackContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
`;

export const PlaybackWrapper = styled.div`
  > video {
    width: 21.25rem;
    height: 21.25rem;

    body[data-mobile='true'] & {
      width: 12.25rem;
      height: 12.25rem;
    }
  }
`;
