import { useCallback, useState } from 'react';
import { faRecordVinyl } from '@fortawesome/pro-regular-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { RMInput, RMInputCharCounter } from '@/components/RMInput';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';

export interface PromptQuestionDialogProps {
  onSubmit: (question: string) => void;
  onClose: () => void;
}

export function PromptQuestionDialog({ onSubmit, onClose }: PromptQuestionDialogProps) {
  const isMobile = useIsMobileViewport();
  const [question, setQuestion] = useState('');

  const handleSubmit = useCallback(() => {
    if (question.length === 0) {
      return;
    }

    return onSubmit(question);
  }, [onSubmit, question]);

  return (
    <RMDialog.Root open variant={isMobile ? 'full-screen' : 'regular'}>
      <RMDialog.Content>
        <RMDialog.Header title="Write your own prompt" rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Body>
          <RMInput
            id="custom-prompt-input"
            label="Your prompt"
            placeholder="Type a question to inspire your story..."
            textarea
            fontFamily="sans"
            rows={6}
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          />
          <RMInputCharCounter maxLength={120} length={question.length} />
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton
            background="primary"
            fullWidth
            autoLoading
            disabled={question.length === 0 || question.length > 120}
            leftIcon={faRecordVinyl}
            onClick={handleSubmit}
          >
            Record my answer
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
