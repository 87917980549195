export enum RecorderMediaStreamStateType {
  Empty = 'Empty',
  Streaming = 'Streaming',
  Finished = 'Finished',
}

export interface RecorderMediaStreamEmptyState {
  type: RecorderMediaStreamStateType.Empty;
}

export interface RecorderMediaStreamStreamingState {
  type: RecorderMediaStreamStateType.Streaming;
  stream: MediaStream;
}

export interface RecorderMediaStreamFinishedState {
  type: RecorderMediaStreamStateType.Finished;
}

export type RecorderMediaStreamState =
  | RecorderMediaStreamEmptyState
  | RecorderMediaStreamStreamingState
  | RecorderMediaStreamFinishedState;
