import { useParams } from 'react-router-dom';

import { useIsBillingOwner, useReactivate } from '@/modules/paywall';
import { PaywallDialog } from '@/modules/paywall/components/PaywallDialog';
import { RecordingLayout } from '@/modules/recording/layouts/RecordingLayout';
import { RementoPage } from '@/modules/routing';

interface InternalRecordingPaywallPageProps {
  projectId: string;
}

function InternalRecordingPaywallPage({ projectId }: InternalRecordingPaywallPageProps) {
  const isBillingOwner = useIsBillingOwner(projectId);
  const handleReactivate = useReactivate(projectId);

  return (
    <RecordingLayout.Root dark Header={<RecordingLayout.Header dark />}>
      <PaywallDialog isBillingOwner={isBillingOwner ?? false} showClose={false} onReactivate={handleReactivate} />
    </RecordingLayout.Root>
  );
}

export function RecordingPaywallPage() {
  const params = useParams();

  return (
    <RementoPage type="empty">
      <InternalRecordingPaywallPage projectId={params.projectId ?? ''} />
    </RementoPage>
  );
}
