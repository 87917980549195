import { useEffect, useState } from 'react';

import { ObservableProgress, Progress } from './progress.types';

export function useProgress(observableProgress: ObservableProgress): Progress {
  const [progress, setProgress] = useState(observableProgress.getProgress());

  useEffect(() => {
    const subscription = observableProgress.addListener(setProgress);
    return () => subscription.remove();
  }, [observableProgress]);

  return progress;
}
