import { useEffect, useState } from 'react';

const maxFrequency = 600;

export function observeAudioFrequencyData(
  stream: MediaStream,
  ticks: number,
  callback: (data: Uint8Array) => void,
): () => void {
  const audioContext = new AudioContext();
  const audioAnalyser = audioContext.createAnalyser();
  audioAnalyser.fftSize = 4096;

  const audioFilter = audioContext.createBiquadFilter();
  audioFilter.type = 'lowpass';
  audioFilter.frequency.value = maxFrequency;
  audioFilter.connect(audioAnalyser);

  const source = audioContext.createMediaStreamSource(stream);
  source.connect(audioFilter);

  let animationFrameId: number | null = null;
  const getData = () => {
    const data = new Uint8Array(ticks);
    audioAnalyser.getByteFrequencyData(data);
    animationFrameId = requestAnimationFrame(getData);
    callback(data);
  };
  getData();

  return () => {
    audioContext.close();
    if (animationFrameId !== null) {
      cancelAnimationFrame(animationFrameId);
    }
  };
}

export function useAudioFrequencyData(stream: MediaStream, ticks: number): Uint8Array {
  const [data, setData] = useState<Uint8Array>(new Uint8Array());

  useEffect(() => {
    return observeAudioFrequencyData(stream, ticks, setData);
  }, [stream, ticks]);

  return data;
}

export function useAudioStreamValid(stream: MediaStream | null): boolean {
  // If the stream received a at least one valid data, that means that the stream is valid.
  // We cannot keep checking in every tick because some noise isolating microphones will stop sending data
  // if the user is not talking.
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(false);
  }, [stream?.id]);

  useEffect(() => {
    if (stream === null) {
      return;
    }

    let unsubscribed = false;
    const unsubscribe = observeAudioFrequencyData(stream, 15, (data) => {
      const isDataValid = data.some((frequency) => frequency > 0);
      if (isDataValid) {
        setValid(true);

        if (unsubscribed === false) {
          unsubscribe();
          unsubscribed = true;
        }
      }
    });

    return () => {
      if (unsubscribed === false) {
        unsubscribe();
        unsubscribed = true;
      }
    };
  }, [stream]);

  return valid;
}
