import { useCallback, useEffect, useMemo, useState } from 'react';
import { faMicrophone, faVideo } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMSelect } from '@/components/RMSelect';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';
import { logger } from '@/logger';
import { captureException } from '@/utils/captureException';

import { RecordWaveformContainer } from '../../containers/RecordWaveform.container';
import { useAudioStreamValid } from '../../hooks/audio-analyzer';

import { MediaContainer, SelectContainer, SelectIcon, Video, WaveFormContainer } from './RecordingDeviceModal.styles';

interface RecordingDeviceModalProps {
  open: boolean;
  type: 'audio' | 'video';
  mediaStream: MediaStream;
  onChangeDevices: (stream: MediaStream) => void;
}

export function RecordingDeviceModal({ open, type, mediaStream, onChangeDevices }: RecordingDeviceModalProps) {
  const [cameras, setCameras] = useState<MediaDeviceInfo[]>([]);
  const [microphones, setMicrophones] = useState<MediaDeviceInfo[]>([]);

  const camerasOptions = useMemo(() => cameras.map((c) => ({ label: c.label, value: c.deviceId })), [cameras]);
  const microphonesOptions = useMemo(
    () => microphones.map((m) => ({ label: m.label, value: m.deviceId })),
    [microphones],
  );

  const [selectedMicrophone, setSelectedMicrophone] = useState(
    () => mediaStream.getAudioTracks()[0]?.getSettings().deviceId ?? '',
  );
  const [selectedCamera, setSelectedCamera] = useState(
    () => mediaStream.getVideoTracks()[0]?.getSettings().deviceId ?? '',
  );

  const [selectedMediaStream, setSelectedMediaStream] = useState(mediaStream);
  const isMediaStreamValid = useAudioStreamValid(selectedMediaStream);

  const [loading, setLoading] = useState(false);

  const handleChangeMicrophone = useCallback(
    async (deviceId: string) => {
      try {
        setLoading(true);
        setSelectedMicrophone(deviceId);

        const newMediaStream = await navigator.mediaDevices.getUserMedia({
          video: type === 'video' ? { deviceId: selectedCamera } : false,
          audio: { deviceId },
        });

        setSelectedMediaStream(newMediaStream);
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      } finally {
        setLoading(false);
      }
    },
    [selectedCamera, type],
  );

  const handleChangeCamera = useCallback(
    async (deviceId: string) => {
      try {
        setLoading(true);
        setSelectedCamera(deviceId);

        const newMediaStream = await navigator.mediaDevices.getUserMedia({
          video: { deviceId: deviceId },
          audio: { deviceId: selectedMicrophone },
        });

        setSelectedMediaStream(newMediaStream);
      } catch (error) {
        toast('An unexpected error has occurred.', 'root-toast', 'error');
        captureException(error, true);
      } finally {
        setLoading(false);
      }
    },
    [selectedMicrophone],
  );

  useEffect(() => {
    const loadDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setCameras(devices.filter((d) => d.kind === 'videoinput'));
      setMicrophones(devices.filter((d) => d.kind === 'audioinput'));

      logger.info('USER_DEVICES_LOADED', {
        cameras: devices.filter((d) => d.kind === 'videoinput'),
        microphones: devices.filter((d) => d.kind === 'audioinput'),
      });
    };
    loadDevices();
  }, []);

  const videoRef = useCallback(
    (element: HTMLVideoElement | null) => {
      if (!element || type !== 'video') {
        return;
      }

      element.srcObject = selectedMediaStream;
    },
    [selectedMediaStream, type],
  );

  const handleContinue = useCallback(() => {
    onChangeDevices(selectedMediaStream);
  }, [onChangeDevices, selectedMediaStream]);

  const message =
    isMediaStreamValid === false
      ? 'Please confirm your audio source to make sure we can hear you.'
      : type === 'audio'
      ? 'Let’s make sure you can be heard properly'
      : 'Let’s make sure you can be seen and heard properly';

  return (
    <RMDialog.Root open={open}>
      <RMDialog.Content>
        <RMDialog.Header title={isMediaStreamValid ? 'Recording Settings' : 'No audio detected'} message={message} />
        <RMDialog.Body>
          <RMDialog.Transition>
            {open && (
              <>
                {type === 'audio' && (
                  <WaveFormContainer>
                    <RecordWaveformContainer stream={selectedMediaStream} size="large" color="blend-70" />
                  </WaveFormContainer>
                )}
                {type === 'video' && (
                  <MediaContainer>
                    <Video muted autoPlay playsInline ref={videoRef} />
                    <RecordWaveformContainer stream={selectedMediaStream} size="medium" color="blend-70" />
                  </MediaContainer>
                )}
              </>
            )}
            <RMSpacer direction="column" spacing="2xl" />
            {type === 'video' && (
              <>
                <SelectContainer>
                  <SelectIcon size="1x" icon={faVideo} />
                  <RMSelect
                    id="video-select"
                    label=""
                    disabled={loading}
                    options={camerasOptions}
                    value={selectedCamera}
                    onChange={handleChangeCamera}
                  />
                </SelectContainer>
                <RMSpacer direction="column" spacing="md" />
              </>
            )}
            <SelectContainer>
              <SelectIcon size="1x" icon={faMicrophone} />
              <RMSelect
                id="audio-select"
                label=""
                disabled={loading}
                options={microphonesOptions}
                value={selectedMicrophone}
                onChange={handleChangeMicrophone}
              />
            </SelectContainer>
          </RMDialog.Transition>
        </RMDialog.Body>

        <RMDialog.Footer>
          <RMButton
            background="primary"
            fullWidth
            loading={loading}
            disabled={isMediaStreamValid === false}
            onClick={handleContinue}
          >
            Continue
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
