import React, { useMemo } from 'react';

import { Container, emptyLineClassName, Text } from './DelayedText.styles';

export type DelayedTextEntry = string | (string | { text: string; className?: string })[];

const ANIMATION_DURATION = 0.2;

export const EmptyLine = { text: '', className: emptyLineClassName };

interface DelayedTextProps {
  text: DelayedTextEntry;
  delay?: number;
  onAnimationEnd?: () => void;
}

function splitWords(text: DelayedTextEntry): { text: string; className?: string }[] {
  const words = Array.isArray(text)
    ? text.flatMap((t) =>
        typeof t === 'string'
          ? t.split(' ').map((text) => ({ text }))
          : t.text.split(' ').map((text) => ({ text, className: t.className })),
      )
    : text.split(' ').map((text) => ({ text }));

  return words;
}

export function DelayedText({ text, delay = 0.1, onAnimationEnd }: DelayedTextProps): JSX.Element {
  const words = useMemo(() => splitWords(text), [text]);

  return (
    <Container>
      {words.map((word, index) => {
        return (
          <React.Fragment key={`${index}-${word.text}`}>
            <Text
              className={word.className}
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: ANIMATION_DURATION, delay: index * delay + 0.5 }}
              onAnimationComplete={index === words.length - 1 ? () => onAnimationEnd?.() : undefined}
            >
              {word.text}&nbsp;
            </Text>
          </React.Fragment>
        );
      })}
    </Container>
  );
}
