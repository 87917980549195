import { useMemo } from 'react';
import { faRecordVinyl } from '@fortawesome/pro-regular-svg-icons';
import { ImageAssetAlternativeType } from '@remento/types/alternative';
import { PromptType } from '@remento/types/project';
import dayjs from 'dayjs';

import { usePersonQuery } from '@/services/api/person';
import { usePromptFirstQuestionQuery, usePromptImageUrl, usePromptQuery } from '@/services/api/project';

import { RecordCard } from '../components/ProjectRecordCard';

interface PromptRecordCardContainerProps {
  promptId: string;
  onRecord: () => void;
}

export function PromptRecordCardContainer({ promptId, onRecord }: PromptRecordCardContainerProps) {
  const promptQuery = usePromptQuery(promptId);
  const questionQuery = usePromptFirstQuestionQuery(promptId);
  const requesterPersonQuery = usePersonQuery(promptQuery.data?.requesterPersonId);
  const mediumImageUrl = usePromptImageUrl(promptQuery.data ?? null, ImageAssetAlternativeType.MEDIUM);
  const largeImageUrl = usePromptImageUrl(promptQuery.data ?? null, ImageAssetAlternativeType.LARGE);

  const headerText = useMemo(() => {
    if (promptQuery.data == null) {
      return null;
    }

    const timeFromNow = dayjs(promptQuery.data.audit.create.date).fromNow();
    if (promptQuery.data?.requesterPersonId != null && requesterPersonQuery.data == null) {
      return null;
    }

    return `${requesterPersonQuery.data?.name?.first ?? 'Someone'} asked ${timeFromNow}`;
  }, [promptQuery.data, requesterPersonQuery.data]);

  if (
    promptQuery.data == null ||
    questionQuery.data == null ||
    headerText == null ||
    (promptQuery.data.type === PromptType.PHOTO && mediumImageUrl == null)
  ) {
    return null;
  }

  return (
    <RecordCard
      header={headerText}
      content={questionQuery.data.text}
      backgroundUrl={mediumImageUrl}
      fullscreenBackgroundUrl={largeImageUrl}
      actionLabel="Record my answer"
      actionIcon={faRecordVinyl}
      onAction={onRecord}
    />
  );
}
