import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { faRecordVinyl } from '@fortawesome/pro-regular-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';
import { useFileDataUrl } from '@/hooks/useFileDataUrl';
import { logger } from '@/logger';
import { submitForm, useIsFormValid } from '@/modules/form/form';
import { PromptEdit } from '@/modules/project/components/PromptEdit/PromptEdit';
import { createPromptEditForm } from '@/modules/project/prompt-edit.form';
import { captureException } from '@/utils/captureException';
import { FilestackHandle, openFilestackPicker } from '@/utils/filestack';

export interface PromptPhotoDialogContainerProps {
  onSubmit: (data: { question: string; photo: FilestackHandle }) => void;
  onClose: () => void;
}

export function PromptPhotoDialogContainer({ onSubmit, onClose }: PromptPhotoDialogContainerProps) {
  const form = useMemo(() => createPromptEditForm({ question: 'What is happening in this photo?' }), []);
  const [photo, setPhoto] = useState<FilestackHandle | null>(null);

  const photoUrl = useFileDataUrl(photo?.originalFile instanceof File ? photo?.originalFile : null);
  const isFormValid = useIsFormValid(form);

  const handleSubmit = useCallback(async () => {
    await submitForm(form, ({ question }) => {
      if (photo == null) {
        logger.warn('UNREACHABLE_STATEMENT');
        return;
      }

      onSubmit({ question, photo });
    });
  }, [form, onSubmit, photo]);

  // Open the file picker on mount
  const filePickerOpenRef = useRef(false);
  useEffect(() => {
    if (filePickerOpenRef.current == true) {
      return;
    }
    filePickerOpenRef.current = true;

    openFilestackPicker({
      accept: ['image/png', 'image/jpeg', 'image/webp'],
      maxSize: 1024 * 1024 * 20,
      onCancel: onClose,
      exposeOriginalFile: true,
      onUploadDone: ({ filesUploaded }) => {
        if (!filesUploaded[0]) {
          return;
        }

        setPhoto(filesUploaded[0]);
      },
    }).catch((error) => captureException(error, true));
  }, [onClose]);

  if (photo == null) {
    return null;
  }

  return (
    <RMDialog.Root open variant="full-screen" onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header title="Your photo prompt" rightAdornment={<RMCloseButton onClick={onClose} />} />
        <RMDialog.Body>
          <PromptEdit form={form} photoUrl={photoUrl ?? photo?.url} onPhotoChange={setPhoto} />
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton
            background="primary"
            fullWidth
            autoLoading
            disabled={isFormValid === false}
            leftIcon={faRecordVinyl}
            onClick={handleSubmit}
          >
            Record my answer
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
