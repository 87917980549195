import { faLink } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDialog } from '@/components/RMDialog';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { toast } from '@/components/RMToast/RMToast';

interface BrowserNotSupportedModalProps {
  open: boolean;
  link: string;
}

const intercomLink =
  'https://intercom.help/remento/en/articles/8677424-how-do-i-record-if-i-see-private-browser-detected';

export function BrowserNotSupportedModal({ open, link }: BrowserNotSupportedModalProps) {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(link);
    toast('Link copied to clipboard', 'root-toast', 'success');
  };

  return (
    <RMDialog.Root open={open}>
      <RMDialog.Content>
        <RMDialog.Header title="Unsupported browser detected" />
        <RMDialog.Body>
          <RMText type="sans" size="s" color="on-surface-primary">
            Use the link below to open this page in a new standard Safari or Chrome window. Learn more{' '}
            <a href={intercomLink} target="_blank">
              <RMText underline type="sans" size="s" color="on-surface-primary">
                here
              </RMText>
            </a>
            .
          </RMText>
          <RMSpacer spacing="2xl" direction="column" />
          <RMInput id="link" value={link} disabled />
        </RMDialog.Body>
        <RMDialog.Footer>
          <RMButton background="primary" leftIcon={faLink} fullWidth onClick={handleCopyLink}>
            Copy link
          </RMButton>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
