import { styled } from '@linaria/react';

export const StyledButtonListItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-lg) var(--spacing-md);
  border: 1px solid var(--border-weak);
  box-shadow: var(--elevation-bottom-2);
  background-color: var(--surface-bright);
  color: var(--primary);
  border-radius: var(--radius-round);
  cursor: pointer;

  :hover {
    background-color: var(--lighten-white-hover);
  }

  :active {
    background-color: var(--lighten-white-press);
  }
`;

export const ImageIcon = styled.img`
  width: 20px;
  height: 20px;
`;
