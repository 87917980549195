import { styled } from '@linaria/react';

export const Container = styled.div<{ height: number; size: 'small' | 'medium' | 'large' }>`
  height: ${(props) => props.height + 'px'};
  display: flex;
  align-items: center;
  gap: ${(props) => (props.size === 'small' ? '3px' : '5px')};
`;

export const Tick = styled.div<{ size: 'small' | 'medium' | 'large' }>`
  border-radius: var(--radius-smooth);
  width: ${(props) => (props.size === 'small' ? '2px' : '4px')};
  min-height: 1px;
`;
