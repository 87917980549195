import { styled } from '@linaria/react';

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-md);
  width: 100%;
  height: 48px;

  > input {
    flex: 1;
    -webkit-appearance: none;

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;
      background-color: var(--on-surface-primary);
      border-radius: var(--radius-full);
      cursor: pointer;
      margin-top: -0.25rem;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0.75rem;
      height: 0.75rem;
      background-color: var(--on-surface-primary);
      border-radius: var(--radius-full);
      cursor: pointer;
      margin-top: -0.25rem;
    }

    &::-moz-range-track {
      width: 100%;
      height: 0.25rem;
      background-color: var(--inverse-on-surface-secondary);
      border-radius: var(--radius-minimal);
    }

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 0.25rem;
      background-color: var(--inverse-on-surface-secondary);
      border-radius: var(--radius-minimal);
    }
  }

  span {
    font-variant-numeric: tabular-nums;
  }

  button > svg {
    color: var(--on-surface-primary);
  }
`;
