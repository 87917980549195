import { PropsWithChildren, useCallback } from 'react';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMCloseButton } from '@/components/RMCloseButton/RMCloseButton';
import { RMDialog } from '@/components/RMDialog';

import { useRecordingPlayback } from '../RecordingPlayback/RecordingPlayback.context';

import { Footer } from './RecordingConfirmDialog.styles';

type RecordingConfirmDialogProps = PropsWithChildren<{
  open: boolean;
  onConfirm?: () => void;
  onRetake?: () => void;
  onClose?: () => void;
}>;

export function RecordingConfirmDialog({ open, onConfirm, onRetake, onClose, children }: RecordingConfirmDialogProps) {
  const { setCurrentTime, setCurrentRecordingIndex } = useRecordingPlayback();

  const resetPlayback = useCallback(() => {
    setCurrentTime(0);
    setCurrentRecordingIndex(0);
  }, [setCurrentRecordingIndex, setCurrentTime]);

  return (
    <RMDialog.Root open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Submit your recording"
          rightAdornment={
            <RMCloseButton
              onClick={() => {
                resetPlayback();
                onClose?.();
              }}
            />
          }
        />

        <RMDialog.Body>{children}</RMDialog.Body>

        <RMDialog.Footer>
          <Footer>
            <RMButton
              background="neutral"
              fullWidth={true}
              autoLoading
              onClick={() => {
                resetPlayback();
                onRetake?.();
              }}
            >
              Start over
            </RMButton>
            <RMButton
              background="primary"
              fullWidth={true}
              autoLoading
              onClick={() => {
                resetPlayback();
                onConfirm?.();
              }}
            >
              Submit
            </RMButton>
          </Footer>
        </RMDialog.Footer>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
