import { MouseEvent, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';
import { throttle } from 'throttle-debounce';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';

import { useRecordingPlayback } from './RecordingPlayback.context';
import { Controls } from './RecordingPlaybackControls.styles';

function formatTime(seconds: number) {
  const date = new Date(seconds * 1000);
  const formatter = new Intl.DateTimeFormat('en', { minute: '2-digit', second: '2-digit' });
  const formatted = formatter.format(date);

  return formatted.startsWith('0') ? formatted.substring(1, formatted.length) : formatted;
}

export function RecordingPlaybackControls() {
  const {
    videoRef,
    currentRecordingIndex,
    durations,
    totalDuration,
    currentTime,
    setCurrentTime,
    setCurrentRecordingIndex,
    onEnded,
  } = useRecordingPlayback();

  // Decrement the previous videos in the list to get the correct time
  const previousTimes = useMemo(
    () => durations.reduce((time, duration, i) => time + (i < currentRecordingIndex ? duration : 0), 0),
    [currentRecordingIndex, durations],
  );

  const [totalTime, setTotalTime] = useState(totalDuration);
  const [isPlaying, setPlaying] = useState(false);

  const [isSeeking, setIsSeeking] = useState(false);
  const isSeekingRef = useRef(isSeeking);
  const [seekTime, setSeekTime] = useState(previousTimes);

  const wasPlaying = useRef(false);

  useLayoutEffect(() => {
    isSeekingRef.current = isSeeking;
  }, [isSeeking]);

  const formattedCurrentTime = useMemo<string | null>(() => {
    return currentTime !== null ? formatTime(previousTimes + currentTime) : null;
  }, [currentTime, previousTimes]);

  const formattedTotalTime = useMemo<string | null>(() => {
    return totalTime !== null ? formatTime(totalTime) : null;
  }, [totalTime]);

  const handlePlay = useCallback(() => {
    if (!videoRef.current) return;
    setPlaying(true);
    videoRef.current.play();
  }, [videoRef]);

  const handlePause = useCallback(() => {
    if (!videoRef.current) return;
    setPlaying(false);
    videoRef.current.pause();
  }, [videoRef]);

  const seek = useMemo(() => {
    return throttle(100, (newTime: number) => {
      if (!videoRef.current) return;
      videoRef.current.currentTime = newTime;
    });
  }, [videoRef]);

  const handleSeek = useCallback(
    (value: string | number) => {
      const newTime = Number(value);

      let durationSum = 0;
      for (const [index, duration] of durations.entries()) {
        durationSum += duration;
        if (durationSum > newTime) {
          if (currentRecordingIndex != index) {
            setCurrentRecordingIndex(index);
          }
          break;
        }
      }

      setSeekTime(newTime);
      seek(newTime);
    },
    [currentRecordingIndex, durations, seek, setCurrentRecordingIndex],
  );

  useEffect(() => {
    if (!videoRef.current) return;

    const handleVideoCurrentTime = throttle(100, () => {
      setCurrentTime(videoRef.current?.currentTime ?? 0);
    });

    const handleVideoMetadata = () => {
      setTotalTime(totalDuration ?? 0);
    };

    const handleVideoEnded = () => {
      if (!videoRef.current || isSeekingRef.current) return;
      if (currentRecordingIndex === durations.length - 1) {
        videoRef.current.currentTime = 0;
        setPlaying(false);
      }
      onEnded();
    };

    videoRef.current.addEventListener('timeupdate', handleVideoCurrentTime);
    videoRef.current.addEventListener('loadedmetadata', handleVideoMetadata);
    videoRef.current.addEventListener('ended', handleVideoEnded);

    return () => {
      if (!videoRef.current) return;

      videoRef.current.removeEventListener('timeupdate', handleVideoCurrentTime);
      videoRef.current.removeEventListener('loadedmetadata', handleVideoMetadata);
      videoRef.current.removeEventListener('ended', handleVideoEnded);
    };
  }, [currentRecordingIndex, durations.length, onEnded, setCurrentTime, totalDuration, videoRef]);

  const handleMouseDown = useCallback(() => {
    setIsSeeking(true);

    if (isPlaying) {
      handlePause();
      wasPlaying.current = true;
    }
  }, [handlePause, isPlaying]);

  const handleMouseUp = useCallback(
    (event: MouseEvent<HTMLInputElement>) => {
      const newTime = event.currentTarget.valueAsNumber;

      seek(newTime - previousTimes);
      setSeekTime(newTime - previousTimes);
      setCurrentTime(newTime - previousTimes);
      setIsSeeking(false);

      if (wasPlaying.current) {
        handlePlay();
        wasPlaying.current = false;
      }
    },
    [handlePlay, previousTimes, seek, setCurrentTime],
  );

  return (
    <Controls>
      {isPlaying ? (
        <RMIconButton tooltip={{ label: 'Pause', position: 'top' }} icon={faPause} onClick={handlePause} />
      ) : (
        <RMIconButton tooltip={{ label: 'Play', position: 'top' }} icon={faPlay} onClick={handlePlay} />
      )}

      {/* max & value has some calculations improvements based on the index to enhance the precision of the track */}
      <input
        type="range"
        min="0"
        max={totalTime}
        value={totalTime > 0 ? (isSeeking ? seekTime : previousTimes + currentTime) : 0}
        step="0.01"
        onChange={(event) => handleSeek(event?.target.value)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      />

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: totalTime > 0 ? 1 : 0 }} transition={{ duration: 0.1 }}>
        <RMText type="sans" size="xxs" color="on-surface-primary">
          {formattedCurrentTime} /{' '}
        </RMText>
        <RMText type="sans" size="xxs" color="on-surface-primary">
          {formattedTotalTime}
        </RMText>
      </motion.div>
    </Controls>
  );
}
