import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { InterviewManagerProvider } from '@/modules/conversation-recorder/interview';
import { RecordContainer } from '@/modules/recording/containers/Record.container';
import { getRecordingTypeSelectionPath, RementoPage } from '@/modules/routing';

export function RecordingPageContainer() {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const recordingType = searchParams.get('type');

  if (recordingType !== 'audio' && recordingType !== 'video') {
    return (
      <Navigate
        to={getRecordingTypeSelectionPath(params.projectId ?? '', params.promptId ?? '', searchParams)}
        replace
      />
    );
  }

  return (
    <RementoPage type="record">
      <InterviewManagerProvider>
        <RecordContainer
          projectId={params.projectId ?? ''}
          promptId={params.promptId ?? ''}
          recordingType={recordingType}
        />
      </InterviewManagerProvider>
    </RementoPage>
  );
}
